import React, { useState, useCallback, useReducer } from "react";

import Input from "../../elements/Input";
import { VALIDATOR_REQUIRE } from "../../../utils/validators";
import formReducer from "../../../utils/formReducer";
import Overlay from "../../elements/Overlay";

const Login = (props) => {
	const [formState, dispatch] = useReducer(formReducer, {
		inputs: {
			username: { value: "", isValid: false },
			password: { value: "", isValid: false }
		},
		isValid: false
	});

	// field states
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const onSubmitClick = (e) => {
		e.preventDefault();
		const userData = {
			username: username,
			password: password
		};
		props.handleSaveClick(userData);
	};

	const inputHandler = useCallback((id, value, isValid, matchAgainst) => {
		dispatch({
			type: "INPUT_CHANGE",
			value: value,
			isValid: isValid,
			inputId: id
		});
		switch (id) {
			case "username":
				setUsername(value);
				break;
			case "password":
				setPassword(value);
				break;
			default:
		}
	}, []);

	return (
		<Overlay onClose={props.onCancel} className="user-sign-up-form">
			<h2 className="sign-up-heading">Log in</h2>
			<form action="" onSubmit={onSubmitClick}>
				<div className="form-column form-column-single">
					<span className="error-text">{props.loginError}</span>
					<Input
						element="input"
						type="text"
						label="Username"
						id="username"
						value=""
						validate
						isValidStart={false}
						validators={[VALIDATOR_REQUIRE()]}
						errorText=" Required"
						usernameUnique={props.usernameUnique}
						onInput={inputHandler}
					/>
					<Input
						element="input"
						type="password"
						label="Password"
						id="password"
						value=""
						validate
						isValidStart={false}
						validators={[VALIDATOR_REQUIRE()]}
						errorText=" Required"
						onInput={inputHandler}
					/>
					<div className="edit-save-cancel">
						<div>
							<input type="submit" value="Log In" disabled={!formState.isValid} />
							<button className="cancel" onClick={props.onCancel}>
								Cancel
							</button>
						</div>
					</div>
					<p className="form-message">
						Forgot your password?{" "}
						<span className="form-link" onClick={props.showForgotPassword}>
							Reset it here.
						</span>
						<br></br><br></br>Don't have an account yet?{" "}
						<span className="form-link" onClick={props.loginSwitch}>
							Sign up here.
						</span>
					</p>
				</div>
			</form>
		</Overlay>
	);
};

export default Login;
