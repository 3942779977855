import React from "react";
import ReactDOM from "react-dom";
import "./Overlay.css";

const Overlay = (props) => {
	const content = (
		<div className="modal-cont">
			<style>
				{`
    body{
      overflow: hidden;
    }
    `}
			</style>
			<div className="overlay" onClick={props.onClose}>
				{props.showX !== false && <i className="fa-solid fa-close"></i>}
			</div>
			<div className={`modal-card ${props.className}`}>{props.children}</div>
		</div>
	);

	return ReactDOM.createPortal(content, document.getElementById("overlay-hook"));
};

export default Overlay;
