import React from "react";
import "./Tab.css";

const Tab = (props) => {
	const setMode = (e) => {
		props.onClick(e.target.id);
	};

	return (
		<div id={props.id} className={`tab ${props.mode === props.id ? "active" : ""}`} onClick={setMode}>
			{props.children}
		</div>
	);
};

export default Tab;
