import React, { useState, useEffect } from "react";

import OwnedFilter from "./Filters/OwnedFilter";
import DebutCardbackFilter from "./Filters/DebutCardbackFilter";
import WantListFilter from "./Filters/WantListFilter";
import HasVariantsFilter from "./Filters/HasVariantsFilter";
import YearReleasedFilter from "./Filters/YearReleasedFilter";
import "./FilterContainer.css";

const Filter = (props) => {
	const [showFilters, setShowFilters] = useState(false);

	const [showOptions, setShowOptions] = useState({
		cardback: false,
		yearReleased: false,
		condition: false,
		grade: false,
		company: false,
		cardbackOwned: false
	});

	useEffect(() => {
		handleShowHideOptions();
	}, [props.mode]);

	const handleResetFilters = () => {
		handleShowHideOptions();
		props.handleFullReset();
		props.handleShowNotification("Filters reset successfully");
	};

	const handleShowHideOptions = (active) => {
		const newOptions = {
			cardback: false,
			yearReleased: false,
			condition: false,
			grade: false,
			company: false,
			cardbackOwned: false
		};

		switch (active) {
			case "cardback":
				setShowOptions((prev) => {
					return { ...newOptions, cardback: !prev.cardback };
				});
				break;
			case "yearReleased":
				setShowOptions((prev) => {
					return { ...newOptions, yearReleased: !prev.yearReleased };
				});
				break;
			case "condition":
				setShowOptions((prev) => {
					return { ...newOptions, condition: !prev.condition };
				});
				break;
			case "grade":
				setShowOptions((prev) => {
					return { ...newOptions, grade: !prev.grade };
				});
				break;
			case "company":
				setShowOptions((prev) => {
					return { ...newOptions, company: !prev.company };
				});
				break;
			case "cardbackOwned":
				setShowOptions((prev) => {
					return { ...newOptions, cardbackOwned: !prev.cardbackOwned };
				});
				break;
			default:
				setShowOptions({ ...newOptions });
				break;
		}
	};

	const handleShowHideFilters = () => {
		setShowFilters((prev) => !prev);
	};

	const handleScrollTop = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth"
		});
	};

	return (
		<>
			<button className="basic show-filters-btn" onClick={handleShowHideFilters}>
				{showFilters ? "Hide" : "Show"} filters{" "}
				<i className={"fa-solid " + (showFilters ? "fa-chevron-up" : "fa-chevron-down")}></i>
			</button>
			<section className={`filter-cont${showFilters ? " visible" : ""}`}>
				<OwnedFilter
					owned={props.owned}
					onChange={props.onChange}
					complete={props.complete}
					exclude={props.exclude}
					mode={props.mode}
					scope={props.scope}
					condition={props.condition}
					grade={props.grade}
					company={props.company}
					cardbackOwned={props.cardbackOwned}
					showConditionOptions={showOptions.condition}
					showGradeOptions={showOptions.grade}
					showCompanyOptions={showOptions.company}
					showCardbackOwnedOptions={showOptions.cardbackOwned}
					handleShowHideOptions={handleShowHideOptions}
				></OwnedFilter>
				<HasVariantsFilter hasVariants={props.hasVariants} onChange={props.onChange}></HasVariantsFilter>
				{props.scope === "base" && (
					<DebutCardbackFilter
						cardbacks={props.cardbacks}
						onChange={props.onChange}
						showCardbackOptions={showOptions.cardback}
						handleShowHideOptions={handleShowHideOptions}
					></DebutCardbackFilter>
				)}
				<YearReleasedFilter
					yearReleased={props.yearReleased}
					onChange={props.onChange}
					showYearReleasedOptions={showOptions.yearReleased}
					handleShowHideOptions={handleShowHideOptions}
				></YearReleasedFilter>
				<WantListFilter wantList={props.wantList} onChange={props.onChange}></WantListFilter>

				<div className="filter-reset-cont">
					<p className="reset-filters" onClick={handleResetFilters}>
						Reset filters
					</p>
				</div>
			</section>
			<div className="mobile-filter-reset-cont">
				<p className="reset-filters" onClick={handleResetFilters}>
					Reset filters
				</p>
			</div>
			<section className="filter-info">
				<div className="result-count">
					<div>
						Showing: <b>{props.count}</b>
					</div>
					<div>
						Collected:{" "}
						<b>
							{props.ownedOfFiltered.length} / {props.count}
						</b>
					</div>
				</div>
				<div className="mobile-top" onClick={handleScrollTop}>
					<i className="fa-solid fa-arrow-up"></i>
				</div>
				<div className="legend-block-cont">
					Border color:
					<div className="legend-block legend-block-green"></div>{" "}
					<div>{props.mode === "loose" ? "owned & complete" : "owned"} </div>
					{props.mode === "loose" && (
						<>
							<div className="legend-block legend-block-yellow"></div> <div>owned & incomplete</div>
						</>
					)}
					<div className="legend-block legend-block-red"></div> <div>owned & excluded from collection count</div>
				</div>
			</section>
		</>
	);
};

export default Filter;
