import React from "react";

const GradeFilter = (props) => {
	const handleShowGradeOptions = () => {
		props.handleShowHideOptions("grade");
	};

	return (
		<div className="filter grade-filter">
			<div className="filter-heading">Grade</div>
			<div className="filter-options show-options">
				<div className={`options-show-hide ${props.showGradeOptions ? "active" : ""}`} onClick={handleShowGradeOptions}>
					<i className={"fa-solid " + (props.showGradeOptions ? "fa-chevron-up" : "fa-chevron-down")}></i>
				</div>
			</div>
			{props.showGradeOptions && (
				<div className="filter-options-cont">
					<div className="filter-options-section">
						<div className="options-section-title">Grade:</div>
						<div
							id="grade-10"
							className={`option-item${props.grade.includes("grade-10") ? " active" : ""}`}
							onClick={props.onChange}
						>
							10
						</div>
						<div
							id="grade-20"
							className={`option-item${props.grade.includes("grade-20") ? " active" : ""}`}
							onClick={props.onChange}
						>
							20
						</div>
						<div
							id="grade-30"
							className={`option-item${props.grade.includes("grade-30") ? " active" : ""}`}
							onClick={props.onChange}
						>
							30
						</div>
						<div
							id="grade-40"
							className={`option-item${props.grade.includes("grade-40") ? " active" : ""}`}
							onClick={props.onChange}
						>
							40
						</div>
						<div
							id="grade-50"
							className={`option-item${props.grade.includes("grade-50") ? " active" : ""}`}
							onClick={props.onChange}
						>
							50
						</div>
						<div
							id="grade-60"
							className={`option-item${props.grade.includes("grade-60") ? " active" : ""}`}
							onClick={props.onChange}
						>
							60
						</div>
						<div
							id="grade-70"
							className={`option-item${props.grade.includes("grade-70") ? " active" : ""}`}
							onClick={props.onChange}
						>
							70
						</div>
						<div
							id="grade-75"
							className={`option-item${props.grade.includes("grade-75") ? " active" : ""}`}
							onClick={props.onChange}
						>
							75
						</div>
						<div
							id="grade-75+"
							className={`option-item${props.grade.includes("grade-75+") ? " active" : ""}`}
							onClick={props.onChange}
						>
							75+
						</div>
						<div
							id="grade-80"
							className={`option-item${props.grade.includes("grade-80") ? " active" : ""}`}
							onClick={props.onChange}
						>
							80
						</div>
						<div
							id="grade-80+"
							className={`option-item${props.grade.includes("grade-80+") ? " active" : ""}`}
							onClick={props.onChange}
						>
							80+
						</div>
						<div
							id="grade-85"
							className={`option-item${props.grade.includes("grade-85") ? " active" : ""}`}
							onClick={props.onChange}
						>
							85
						</div>
						<div
							id="grade-85+"
							className={`option-item${props.grade.includes("grade-85+") ? " active" : ""}`}
							onClick={props.onChange}
						>
							85+
						</div>
						<div
							id="grade-90"
							className={`option-item${props.grade.includes("grade-90") ? " active" : ""}`}
							onClick={props.onChange}
						>
							90
						</div>
						<div
							id="grade-90+"
							className={`option-item${props.grade.includes("grade-90+") ? " active" : ""}`}
							onClick={props.onChange}
						>
							90+
						</div>
						<div
							id="grade-95"
							className={`option-item${props.grade.includes("grade-95") ? " active" : ""}`}
							onClick={props.onChange}
						>
							95
						</div>
						<div
							id="grade-95+"
							className={`option-item${props.grade.includes("grade-95+") ? " active" : ""}`}
							onClick={props.onChange}
						>
							95+
						</div>
						<div
							id="grade-100"
							className={`option-item${props.grade.includes("grade-100") ? " active" : ""}`}
							onClick={props.onChange}
						>
							100
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default GradeFilter;
