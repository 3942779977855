import React from "react";

import "./Search.css";

const Search = (props) => {
	return (
		<div className="search-box">
			{props.value !== "" && (
				<span className="clear-search" onClick={props.onClear}>
					clear
				</span>
			)}
			<input type="text" autoFocus placeholder="search figure name" onChange={props.onSearch} value={props.value} />{" "}
		</div>
	);
};

export default Search;
