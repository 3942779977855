import React from "react";

import Card from "./Card";
import "./Container.css";

const Container = (props) => {
	return (
		<>
			<div className="container">
				{props.data.map((item) => (
					<Card
						cardData={item}
						complete={item.complete}
						key={item._id}
						compact={props.compact}
						handleImageClick={props.handleImageClick}
						username={props.username}
						token={props.token}
						onShowVariants={props.onShowVariants}
						showVariantForm={props.showVariantForm}
						onShowDuplicates={props.onShowDuplicates}
						showDuplicateForm={props.showDuplicateForm}
						parentData={props.parentData && props.parentData} // variantsParentData
						variantsData={props.variantsData && props.variantsData}
						duplicatesParentData={props.duplicatesParentData && props.duplicatesParentData}
						handleRefresh={props.handleRefresh}
						setTheVariantsData={props.setTheVariantsData}
						setTheDuplicatesData={props.setTheDuplicatesData}
						showNotification={props.showNotification}
						mode={props.mode}
						scope={props.scope}
						sharedLink={props.sharedLink}
						handleShowUserSignUpForm={props.handleShowUserSignUpForm}
					></Card>
				))}
			</div>
			{props.data.length === 0 && (
				<h2 className="no-results">
					<div>There are no results for those filters</div>
				</h2>
			)}
		</>
	);
};

export default Container;
