import React, { useRef, useState, useEffect } from "react";

import "./ImageUpload.css";

const ImageUpload = (props) => {
	const [file, setFile] = useState();
	const [previewUrl, setPreviewUrl] = useState(props.previewUrl);
	const [isValid, setIsValid] = useState(false);
	const [usingCustomImage, setUsingCustomImage] = useState(props.usingCustomImage);
	const [resettingImage, setResettingImage] = useState(false);

	const filePickerRef = useRef();

	useEffect(() => {
		if (!file) {
			return;
		}
		const fileReader = new FileReader();
		fileReader.onload = () => {
			setPreviewUrl(fileReader.result);
		};
		fileReader.readAsDataURL(file);
	}, [file]);

	const pickedHandler = (e) => {
		let pickedFile;
		let fileIsValid = isValid;
		if (e.target.files && e.target.files.length === 1) {
			pickedFile = e.target.files[0];
			setFile(pickedFile);
			setIsValid(true);
			fileIsValid = true;
		} else {
			setIsValid(false);
			fileIsValid = false;
		}
		props.onInput(props.id, pickedFile, fileIsValid);
	};

	const pickImageHandler = (e) => {
		filePickerRef.current.click();
	};

	const handleCancelImage = () => {
		props.removeCustomImage(false);
		setFile(null);
		props.onInput(props.id, null, true);
		setPreviewUrl(props.previewUrl);
		setResettingImage(false);
	};

	const deleteCustomImage = () => {
		props.removeCustomImage(true);
		setPreviewUrl(process.env.REACT_APP_IMAGES_DOMAIN + "/images/default/" + props.originalImage);
		setUsingCustomImage(false);
		setResettingImage(true);
	};

	return (
		<div className="form-control">
			<label htmlFor={props.id}>Image</label>
			<input
				ref={filePickerRef}
				id={props.id}
				style={{ display: "none" }}
				type="file"
				accept=".jpg, .png, .jpeg"
				onChange={pickedHandler}
			/>
			<div className="image-upload">
				<div className="image-upload-preview">{previewUrl && <img src={previewUrl} alt="Preview" />}</div>
				<div className="form-image-links">
					<span>
						<span className="form-link" onClick={pickImageHandler}>
							Pick a{props.add ? "n" : " new"} image
						</span>
						{usingCustomImage && !file && (
							<span>
								&nbsp;or{" "}
								<span className="form-link" onClick={deleteCustomImage}>
									Reset to default
								</span>
							</span>
						)}
					</span>
					{(file || resettingImage) && (
						<span className="form-image-cancel" onClick={handleCancelImage}>
							Undo
						</span>
					)}
				</div>
			</div>
			{!isValid && <p>{props.errorText}</p>}
		</div>
	);
};

export default ImageUpload;
