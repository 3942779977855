import React, { useState, useCallback, useReducer } from "react";

import Overlay from "../../elements/Overlay";
import { VALIDATOR_REQUIRE } from "../../../utils/validators";
import Input from "../../elements/Input";
import "../../elements/Input.css";
import formReducer from "../../../utils/formReducer";
import Confirm from "../Confirm";
import ImageUpload from "../../elements/ImageUpload";

const EditCard = (props) => {
	const [formState, dispatch] = useReducer(formReducer, {
		inputs: {
			name: { value: "", isValid: true }
		},
		isValid: true
	});

	const [cardData, setCardData] = useState(props.cardData);
	const [showAddAcc, setShowAddAcc] = useState(false);
	const [addAccText, setAddAccText] = useState("");
	const [changesMade, setChangesMade] = useState(false);
	const [showAddVariantConfirm, setShowAddVariantConfirm] = useState(false);
	const [showViewVariantsConfirm, setShowViewVariantsConfirm] = useState(false);
	const [showAddDuplicateConfirm, setShowAddDuplicateConfirm] = useState(false);
	const [showViewDuplicatesConfirm, setShowViewDuplicatesConfirm] = useState(false);
	const [deleteCustomImage, setDeleteCustomImage] = useState(false);
	const [ownedYes, setOwnedYes] = useState(props.cardData.owned);

	const handleStringChange = (e) => {
		setChangesMade(true);

		const el = e.target.id;
		const val = e.target.value;
		switch (el) {
			case "name":
				setCardData((prev) => ({ ...prev, name: val }));
				break;
			case "scope":
				setCardData((prev) => ({ ...prev, scope: val }));
				break;
			case "image":
				setCardData((prev) => ({ ...prev, image: val }));
				break;
			case "variant":
				setCardData((prev) => ({ ...prev, variant: val }));
				break;
			case "coo":
				setCardData((prev) => ({ ...prev, coo: val }));
				break;
			case "licensee":
				setCardData((prev) => ({ ...prev, licensee: val }));
				break;
			case "factory":
				setCardData((prev) => ({ ...prev, factory: val }));
				break;
			case "year-purchased":
				setCardData((prev) => ({ ...prev, yearPurchased: val }));
				break;
			case "price-paid":
				setCardData((prev) => ({ ...prev, pricePaid: val }));
				break;
			case "purchased-from":
				setCardData((prev) => ({ ...prev, whereBought: val }));
				break;
			case "condition":
				setCardData((prev) => ({ ...prev, condition: val }));
				break;
			case "notes":
				setCardData((prev) => ({ ...prev, notes: val }));
				break;
			case "year-released":
				setCardData((prev) => ({ ...prev, yearReleased: val }));
				break;
			case "release-order":
				setCardData((prev) => ({ ...prev, releaseOrder: val }));
				break;
			case "debut-cardback":
				setCardData((prev) => ({ ...prev, debutCardback: val }));
				break;
			case "year-replaced":
				setCardData((prev) => ({ ...prev, yearReplaced: val }));
				break;
			case "grade":
				setCardData((prev) => ({ ...prev, grade: val }));
				break;
			case "grade-qualifier":
				setCardData((prev) => ({ ...prev, gradeQualifier: val }));
				break;
			case "sub-grades":
				setCardData((prev) => ({ ...prev, subGrades: val }));
				break;
			case "company":
				setCardData((prev) => ({ ...prev, company: val }));
				break;
			case "date-graded":
				setCardData((prev) => ({ ...prev, dateGraded: val }));
				break;
			case "cardback-owned":
				setCardData((prev) => ({ ...prev, cardbackOwned: val }));
				break;
			default:
		}
	};

	const handleBoolChange = (e) => {
		setChangesMade(true);

		const el = e.target.id;
		switch (el) {
			case "owned-yes":
				setCardData((prev) => ({ ...prev, owned: true }));
				if (props.mode !== "loose") {
					// set all accessories to owned = true
					setCardData((prev) => {
						return {
							...prev,
							accessories: prev.accessories.map((item, index) => {
								return {
									...item,
									owned: true
								};
							})
						};
					});
				}
				handleSetOwned(true);
				break;
			case "owned-no":
				setCardData((prev) => ({ ...prev, owned: false, replace: false }));
				if (props.mode !== "loose") {
					// set all accessories to owned = false
					setCardData((prev) => {
						return {
							...prev,
							accessories: prev.accessories.map((item, index) => {
								return {
									...item,
									owned: false
								};
							})
						};
					});
				}
				handleSetOwned(false);
				break;
			case "replace-yes":
				setCardData((prev) => ({ ...prev, replace: true }));
				break;
			case "replace-no":
				setCardData((prev) => ({ ...prev, replace: false }));
				break;
			case "wantlist-yes":
				setCardData((prev) => ({ ...prev, wantList: true }));
				break;
			case "wantlist-no":
				setCardData((prev) => ({ ...prev, wantList: false }));
				break;
			default:
		}
	};

	const handleSaveClick = (e) => {
		const cardDataToSave = deleteCustomImage ? { ...cardData, customImage: null } : cardData;
		e.preventDefault();
		let imageValue;
		if (formState.inputs.image) {
			imageValue = formState.inputs.image.value;
		}
		props.onSave(cardDataToSave, imageValue ? formState.inputs.image.value : null, formState.isValid);
	};

	const handleAddAccClick = (e) => {
		setChangesMade(true);
		e.preventDefault();
		setShowAddAcc((prev) => !prev);
	};

	const handleAddAccTextChange = (e) => {
		setChangesMade(true);
		setAddAccText(e.target.value);
	};

	const handleAddAccessory = (e) => {
		setChangesMade(true);
		e.preventDefault();
		const val = addAccText;
		setCardData((prev) => ({ ...prev, accessories: [...prev.accessories, { name: val, owned: false }] }));
		setShowAddAcc((prev) => !prev);
	};

	const handleDeleteAccessory = (e) => {
		setChangesMade(true);
		const i = e.target.id;
		setCardData((prev) => ({
			...prev,
			accessories: prev.accessories.filter((item, index) => index.toString() !== i)
		}));
	};

	const handleCheckAccessory = (e) => {
		setChangesMade(true);
		const i = e.target.value;
		// Set the card data with an updated accessories array
		setCardData((prev) => {
			return {
				...cardData,
				accessories: cardData.accessories.map((item, index) => {
					return {
						...item,
						owned: index.toString() === i ? !prev.accessories[i].owned : item.owned
					};
				})
			};
		});
	};

	const handleAccNameChange = (e) => {
		setChangesMade(true);
		const i = e.target.id;
		const name = e.target.value;
		// Set the card data with an updated accessories array
		setCardData((prev) => ({
			...prev,
			accessories: prev.accessories.map((item, index) => {
				return {
					...item,
					name: index.toString() === i ? name : item.name
				};
			})
		}));
	};

	const inputHandler = useCallback((id, value, isValid) => {
		dispatch({
			type: "INPUT_CHANGE",
			value: value,
			isValid: isValid,
			inputId: id
		});
		switch (id) {
			case "name":
				setCardData((prev) => ({ ...prev, name: value }));
				break;
			default:
		}
	}, []);

	const showVariantForm = () => {
		if (changesMade) {
			setShowAddVariantConfirm(true);
		} else {
			props.showVariantForm();
		}
	};

	const showVariants = () => {
		if (changesMade) {
			setShowViewVariantsConfirm(true);
		} else {
			props.showVariants();
		}
	};

	const showDuplicateForm = () => {
		if (changesMade) {
			setShowAddDuplicateConfirm(true);
		} else {
			props.showDuplicateForm(props.parentData ? true : false);
		}
	};

	const showDuplicates = () => {
		if (changesMade) {
			setShowViewDuplicatesConfirm(true);
		} else {
			props.showDuplicates();
		}
	};

	const handleAddConfirmOk = () => {
		setShowAddVariantConfirm(false);
		const cardDataToSave = deleteCustomImage ? { ...cardData, customImage: null } : cardData;
		let imageValue;
		if (formState.inputs.image) {
			imageValue = formState.inputs.image.value;
		}
		props.onSave(cardDataToSave, imageValue ? formState.inputs.image.value : null, formState.isValid);
		props.setTheVariantsData(cardData.variants, cardDataToSave);
		props.showVariantForm();
	};

	const handleAddConfirmCancel = () => {
		props.showVariantForm();
		setShowAddVariantConfirm(false);
	};

	const handleViewConfirmOk = () => {
		setShowViewVariantsConfirm(false);
		let imageValue;
		if (formState.inputs.image) {
			imageValue = formState.inputs.image.value;
		}
		props.onSave(cardData, imageValue ? formState.inputs.image.value : null, formState.isValid);
		props.showVariants();
	};

	const handleViewConfirmCancel = () => {
		props.showVariants();
		setShowViewVariantsConfirm(false);
	};

	const handleAddDuplicateConfirmOk = () => {
		setShowAddDuplicateConfirm(false);
		let imageValue;
		if (formState.inputs.image) {
			imageValue = formState.inputs.image.value;
		}
		props.onSave(cardData, imageValue ? formState.inputs.image.value : null, formState.isValid);
		props.showDuplicateForm(props.parentData ? true : false);
	};

	const handleAddDuplicateConfirmCancel = () => {
		props.showDuplicateForm();
		setShowAddDuplicateConfirm(false);
	};

	const handleViewDuplicateConfirmOk = () => {
		setShowViewDuplicatesConfirm(false);
		let imageValue;
		if (formState.inputs.image) {
			imageValue = formState.inputs.image.value;
		}
		props.onSave(cardData, imageValue ? formState.inputs.image.value : null, formState.isValid);
		props.showDuplicates();
	};

	const handleViewDuplicateConfirmCancel = () => {
		props.showDuplicates();
		setShowViewDuplicatesConfirm(false);
	};

	const removeCustomImage = (remove) => {
		setDeleteCustomImage(remove);
	};

	const handleSetOwned = (owned) => {
		setOwnedYes(owned);
	};

	return (
		<>
			{showAddVariantConfirm && (
				<Confirm
					onCancel={handleAddConfirmCancel}
					onOk={handleAddConfirmOk}
					text="Save your changes first?"
					yes="Yes"
					no="No"
				></Confirm>
			)}
			{showViewVariantsConfirm && (
				<Confirm
					onCancel={handleViewConfirmCancel}
					onOk={handleViewConfirmOk}
					text="Save your changes first?"
					yes="Yes"
					no="No"
				></Confirm>
			)}
			{showAddDuplicateConfirm && (
				<Confirm
					onCancel={handleAddDuplicateConfirmCancel}
					onOk={handleAddDuplicateConfirmOk}
					text="Save your changes first?"
					yes="Yes"
					no="No"
				></Confirm>
			)}
			{showViewDuplicatesConfirm && (
				<Confirm
					onCancel={handleViewDuplicateConfirmCancel}
					onOk={handleViewDuplicateConfirmOk}
					text="Save your changes first?"
					yes="Yes"
					no="No"
				></Confirm>
			)}
			<Overlay
				onClose={props.onCancel}
				className={"edit-card " + (props.collected ? "collected " : " ") + (props.compact && "compact ")}
			>
				<h2>
					<div>
						<span className="tag">
							{props.mode === "loose"
								? "Loose"
								: props.mode === "loose-graded"
								? "Loose Graded"
								: props.mode === "moc"
								? "MOC/MISB"
								: "MOC/MISB Graded"}
						</span>
						<span className="tag">
							{props.scope === "base" ||
							(props.parentData && props.parentData.scope === "base") ||
							(props.duplicatesParentData && props.duplicatesParentData.scope === "base")
								? "Figure"
								: props.scope === "creatures" ||
								  (props.parentData && props.parentData.scope === "creatures") ||
								  (props.duplicatesParentData && props.duplicatesParentData.scope === "creatures")
								? "Creature"
								: props.scope === "vehicles" ||
								  (props.parentData && props.parentData.scope === "vehicles") ||
								  (props.duplicatesParentData && props.duplicatesParentData.scope === "vehicles")
								? "Vehicle"
								: props.scope === "playsets" ||
								  (props.parentData && props.parentData.scope === "playsets") ||
								  (props.duplicatesParentData && props.duplicatesParentData.scope === "playsets")
								? "Playset"
								: "Mini-rig"}
						</span>
						{props.parentData && <span className="tag">Variant</span>}
						{props.duplicatesParentData && <span className="tag">Duplicate</span>}
						<br></br>
						<span className="editing-label">Editing</span> {cardData.name}{" "}
						<span className="editing-label">
							{props.duplicatesParentData && "duplicate"} {props.parentData && "variant"}
						</span>
					</div>
					<div className="mobile-close" onClick={props.onCancel}>
						<i className="fa fa-solid fa-close"></i>
					</div>
					<div>
						<input type="submit" value="Save" disabled={!formState.isValid} onClick={handleSaveClick} />
						<button className="cancel" onClick={props.onCancel}>
							Cancel
						</button>
					</div>
				</h2>
				<form action="">
					<div className="form-column form-column-one">
						{(props.duplicatesParentData || props.parentData) && (
							<Input
								element="input"
								type="text"
								label="Name"
								id="name"
								value={cardData.name}
								onInput={inputHandler}
								errorText=" Required"
								validate
								isValidStart={true}
								validators={[VALIDATOR_REQUIRE()]}
							/>
						)}
						{props.parentData && (
							<Input
								element="input"
								type="text"
								label="Year released"
								id="year-released"
								value={cardData.yearReleased}
								onInput={inputHandler}
								isValidStart={true}
								onChange={handleStringChange}
							/>
						)}
						<ImageUpload
							id="image"
							onInput={inputHandler}
							previewUrl={props.imageUrl}
							originalImage={props.cardData.image}
							usingCustomImage={props.usingCustomImage}
							removeCustomImage={removeCustomImage}
						></ImageUpload>
						{!props.duplicatesParentData && (
							<Input
								className="form-radio"
								element="input"
								type="radio"
								label="Owned"
								id="owned-radio"
								onChange={handleBoolChange}
								radios={[
									{ id: "owned-yes", checked: cardData.owned, label: "Yes" },
									{ id: "owned-no", checked: !cardData.owned, label: "No" }
								]}
							/>
						)}
						{!props.duplicatesParentData && ownedYes && !props.parentData && (
							<div className="form-control">
								<label>Quantity owned</label>
								<p>
									{cardData.duplicates && cardData.duplicates.length > 0 ? (
										<span>
											{cardData.duplicates.length + 1}
											<span className="form-link" onClick={showDuplicates}>
												<br></br>(View duplicates)
											</span>
										</span>
									) : (
										<span>
											1
											<br />
											<span className="form-link" onClick={showDuplicateForm}>
												Add a duplicate
											</span>
										</span>
									)}
								</p>
							</div>
						)}
						{!props.parentData && !props.duplicatesParentData && ownedYes && (
							<Input
								className="form-radio"
								element="input"
								type="radio"
								label="Exclude from collection count"
								id="replace-radio"
								onChange={handleBoolChange}
								radios={[
									{ id: "replace-yes", checked: cardData.replace, label: "Yes" },
									{ id: "replace-no", checked: !cardData.replace, label: "No" }
								]}
							/>
						)}
						<Input
							element="input"
							type="text"
							label="Variant info"
							id="variant"
							value={cardData.variant}
							onChange={handleStringChange}
						/>
						<Input
							element="input"
							type="text"
							label="COO"
							id="coo"
							value={cardData.coo}
							onChange={handleStringChange}
						/>
						<Input
							element="select"
							label="Licensee"
							id="licensee"
							value={cardData.licensee}
							onChange={handleStringChange}
							options={[
								{ value: "", label: "-- select an option --" },
								{ value: "clipper", label: "Clipper" },
								{ value: "glasslite", label: "Glasslite" },
								{ value: "kenner", label: "Kenner" },
								{ value: "lili-ledy", label: "Lili Ledy" },
								{ value: "meccano", label: "Meccano" },
								{ value: "model-trem", label: "Model Trem" },
								{ value: "pbp", label: "PBP" },
								{ value: "palitoy", label: "Palitoy" },
								{ value: "poch", label: "Poch" },
								{ value: "takara", label: "Takara" },
								{ value: "toltoys", label: "Toltoys" },
								{ value: "top-toys", label: "TopToys" },
								{ value: "uzay", label: "Uzay" }
							]}
						/>
						<Input
							element="select"
							label="Factory"
							id="factory"
							value={cardData.factory}
							onChange={handleStringChange}
							options={[
								{ value: "", label: "-- select an option --" },
								{ value: "kader", label: "Kader" },
								{ value: "unitoy", label: "Unitoy" },
								{ value: "smile", label: "Smile" },
								{ value: "universal", label: "Universal" },
								{ value: "jetta", label: "Jetta" },
								{ value: "qualidux", label: "Qualidux" },
								{ value: "great-shing", label: "Great Shing" },
								{ value: "taiwan", label: "Taiwan" }
							]}
						/>
					</div>
					<div className="form-column form-column-two">
						<div className="form-control accessories-edit">
							<label htmlFor="accessories">
								Accessories <span style={{ fontWeight: "400" }}>(checked = owned)</span>
							</label>
							{cardData.accessories.map((item, index) => {
								return (
									<div key={index}>
										<input
											type="checkbox"
											value={index}
											checked={props.mode === "loose" ? cardData.accessories[index].owned : cardData.owned}
											onChange={handleCheckAccessory}
											disabled={props.mode !== "loose"}
										/>{" "}
										<input type="text" id={index} value={item.name} onChange={handleAccNameChange} />
										&nbsp;
										<span className="delete-acc">
											<i className="fa-solid fa-close" id={index} onClick={handleDeleteAccessory}></i>
										</span>
									</div>
								);
							})}
							{!showAddAcc ? (
								<span className="form-link" onClick={handleAddAccClick}>
									Add an accessory
								</span>
							) : (
								<div className="add-accessory-div">
									<input type="text" id="add-accessory-field" onChange={handleAddAccTextChange} />
									<button onClick={handleAddAccessory}>Add</button>
									<span className="accessory-cancel" onClick={handleAddAccClick}>
										Cancel
									</span>
								</div>
							)}
						</div>
						{!props.parentData && !props.duplicatesParentData && (
							<div className="form-control">
								<label>Variants</label>
								<p>
									{cardData.variants.length > 0 ? (
										<span>
											{cardData.variants.length} {cardData.variants.length > 1 ? "variants" : "variant"}{" "}
											<span className="form-link" onClick={showVariants}>
												(View)
											</span>
										</span>
									) : (
										<span>
											None
											<br />
											<span className="form-link" onClick={showVariantForm}>
												Add a variant
											</span>
										</span>
									)}
								</p>
							</div>
						)}
						<Input
							element="input"
							type="text"
							label="Sort order"
							id="release-order"
							value={cardData.releaseOrder}
							onChange={handleStringChange}
						/>
						{(props.mode === "moc" || props.mode === "moc-graded") &&
							(props.cardData.scope === "base" || (props.parentData && props.parentData.scope === "base")) && (
								<Input
									element="select"
									label="Cardback owned"
									id="cardback-owned"
									value={cardData.cardbackOwned}
									onChange={handleStringChange}
									options={[
										{ value: "", label: "-- select an option --" },
										{ value: "sw-12", label: "Star Wars 12-Back" },
										{ value: "sw-20", label: "Star Wars 20-Back" },
										{ value: "sw-21", label: "Star Wars 21-Back" },
										{ value: "esb-31", label: "The Empire Strikes Back 31-Back" },
										{ value: "esb-32", label: "The Empire Strikes Back 32-Back" },
										{ value: "esb-41", label: "The Empire Strikes Back 41-Back" },
										{ value: "esb-45", label: "The Empire Strikes Back 45-Back" },
										{ value: "esb-47", label: "The Empire Strikes Back 47-Back" },
										{ value: "esb-48", label: "The Empire Strikes Back 48-Back" },
										{ value: "rotj-65", label: "Return of the Jedi 65-Back" },
										{ value: "rotj-77", label: "Return of the Jedi 77-Back" },
										{ value: "rotj-79", label: "Return of the Jedi 79-Back" },
										{ value: "potf-92", label: "Power of the Force 92-Back" },
										{ value: "tri-logo", label: "Tri-logo" }
									]}
								/>
							)}
						<Input
							element="input"
							type="text"
							label="Date purchased"
							id="year-purchased"
							value={cardData.yearPurchased}
							onChange={handleStringChange}
						/>
						<Input
							element="input"
							type="text"
							label="Price paid"
							id="price-paid"
							value={cardData.pricePaid}
							onChange={handleStringChange}
						/>
						<Input
							element="input"
							type="text"
							label="Purchased from"
							id="purchased-from"
							value={cardData.whereBought}
							onChange={handleStringChange}
						/>
						{(props.mode === "loose" || props.mode === "moc") && (
							<Input
								element="select"
								label="Condition"
								id="condition"
								value={cardData.condition}
								onChange={handleStringChange}
								options={[
									{ value: "", label: "-- select an option --" },
									{ value: "10", label: "10" },
									{ value: "9", label: "9" },
									{ value: "8", label: "8" },
									{ value: "7", label: "7" },
									{ value: "6", label: "6" },
									{ value: "5", label: "5" },
									{ value: "4", label: "4" },
									{ value: "3", label: "3" },
									{ value: "2", label: "2" },
									{ value: "1", label: "1" }
								]}
							/>
						)}
						{(props.mode === "loose-graded" || props.mode === "moc-graded") && (
							<>
								<Input
									element="select"
									label="Grading company"
									id="company"
									value={cardData.company}
									onChange={handleStringChange}
									options={[
										{ value: "", label: "-- select an option --" },
										{ value: "AFA", label: "AFA" },
										{ value: "CAS", label: "CAS" },
										{ value: "UKG", label: "UKG" }
									]}
								/>
								<Input
									element="select"
									label="Grade"
									id="grade"
									value={cardData.grade}
									onChange={handleStringChange}
									options={[
										{ value: "", label: "-- select an option --" },
										{ value: "10", label: "10" },
										{ value: "20", label: "20" },
										{ value: "30", label: "30" },
										{ value: "40", label: "40" },
										{ value: "50", label: "50" },
										{ value: "60", label: "60" },
										{ value: "70", label: "70" },
										{ value: "75", label: "75" },
										{ value: "75+", label: "75+" },
										{ value: "80", label: "80" },
										{ value: "80+", label: "80+" },
										{ value: "85", label: "85" },
										{ value: "85+", label: "85+" },
										{ value: "90", label: "90" },
										{ value: "90+", label: "90+" },
										{ value: "95", label: "95" },
										{ value: "95+", label: "95+" },
										{ value: "100", label: "100" }
									]}
								/>
								<Input
									element="select"
									label="Grade qualifier"
									id="grade-qualifier"
									value={cardData.gradeQualifier}
									onChange={handleStringChange}
									options={[
										{ value: "", label: "-- select an option --" },
										{ value: "Yellowing", label: "Yellowing" },
										{ value: "Qualified", label: "Qualified" },
										{ value: "Uncirculated", label: "Uncirculated" }
									]}
								/>
								{props.mode === "moc-graded" && (
									<Input
										element="input"
										type="text"
										label="Sub grades"
										id="sub-grades"
										value={cardData.subGrades}
										onChange={handleStringChange}
									/>
								)}
								<Input
									element="input"
									type="text"
									label="Date graded"
									id="date-graded"
									value={cardData.dateGraded}
									onChange={handleStringChange}
								/>
							</>
						)}
						{!props.parentData && !props.duplicatesParentData && (
							<Input
								className="form-radio"
								element="input"
								type="radio"
								label="Include in want list"
								id="wantlist-radio"
								onChange={handleBoolChange}
								radios={[
									{ id: "wantlist-yes", checked: cardData.wantList, label: "Yes" },
									{ id: "wantlist-no", checked: !cardData.wantList, label: "No" }
								]}
							/>
						)}
						<Input
							element="textarea"
							label="Notes: "
							value={cardData.notes}
							rows="5"
							id="notes"
							maxLength="300"
							onChange={handleStringChange}
						/>
					</div>
					<div className={`edit-save-cancel ${props.parentData || props.duplicatesParentData ? "with-delete" : ""}`}>
						{props.parentData && (
							<button className="danger" onClick={props.onDelete}>
								Delete this variant
							</button>
						)}
						{props.duplicatesParentData && (
							<button className="danger" onClick={props.onDelete}>
								Delete this duplicate
							</button>
						)}
						<div>
							<input type="submit" value="Save" disabled={!formState.isValid} onClick={handleSaveClick} />
							<button className="cancel" onClick={props.onCancel}>
								Cancel
							</button>
						</div>
					</div>
				</form>
			</Overlay>
		</>
	);
};

export default EditCard;
