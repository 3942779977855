import React from "react";

const YearReleasedFilter = (props) => {
	const handleShowYearReleasedOptions = () => {
		props.handleShowHideOptions("yearReleased");
	};

	return (
		<div className="filter year-released-filter">
			<div className="filter-heading">Year released</div>
			<div className="filter-options show-options">
				<div
					className={`options-show-hide ${props.showYearReleasedOptions ? "active" : ""}`}
					onClick={handleShowYearReleasedOptions}
				>
					<i className={"fa-solid " + (props.showYearReleasedOptions ? "fa-chevron-up" : "fa-chevron-down")}></i>
				</div>
			</div>
			{props.showYearReleasedOptions && (
				<div className="filter-options-cont">
					<div className="filter-options-section">
						<div className="options-section-title">Year:</div>
						<div
							id="year-released-1977"
							className={`option-item${props.yearReleased.includes("year-released-1977") ? " active" : ""}`}
							onClick={props.onChange}
						>
							1977
						</div>
						<div
							id="year-released-1978"
							className={`option-item${props.yearReleased.includes("year-released-1978") ? " active" : ""}`}
							onClick={props.onChange}
						>
							1978
						</div>
						<div
							id="year-released-1979"
							className={`option-item${props.yearReleased.includes("year-released-1979") ? " active" : ""}`}
							onClick={props.onChange}
						>
							1979
						</div>
						<div
							id="year-released-1980"
							className={`option-item${props.yearReleased.includes("year-released-1980") ? " active" : ""}`}
							onClick={props.onChange}
						>
							1980
						</div>
						<div
							id="year-released-1981"
							className={`option-item${props.yearReleased.includes("year-released-1981") ? " active" : ""}`}
							onClick={props.onChange}
						>
							1981
						</div>
						<div
							id="year-released-1982"
							className={`option-item${props.yearReleased.includes("year-released-1982") ? " active" : ""}`}
							onClick={props.onChange}
						>
							1982
						</div>
						<div
							id="year-released-1983"
							className={`option-item${props.yearReleased.includes("year-released-1983") ? " active" : ""}`}
							onClick={props.onChange}
						>
							1983
						</div>
						<div
							id="year-released-1984"
							className={`option-item${props.yearReleased.includes("year-released-1984") ? " active" : ""}`}
							onClick={props.onChange}
						>
							1984
						</div>
						<div
							id="year-released-1985"
							className={`option-item${props.yearReleased.includes("year-released-1985") ? " active" : ""}`}
							onClick={props.onChange}
						>
							1985
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default YearReleasedFilter;
