import React from "react";

import Overlay from "../elements/Overlay";
import "./Confirm.css";

const Confirm = (props) => {
	return (
		<Overlay showX={false} className="confirm-modal">
			<h2>{props.text}</h2>
			<div className="confirm-buttons">
				<button onClick={props.onOk}>{props.yes}</button>
				<button className="cancel" onClick={props.onCancel}>
					{props.no}
				</button>
			</div>
		</Overlay>
	);
};

export default Confirm;
