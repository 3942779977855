import React, { useState, useContext } from "react";
import axios from "axios";

import { AuthContext } from "../../context/auth-context";
import EditCard from "../modals/forms/EditCard";
import FullDetails from "../modals/FullDetails";
import Confirm from "../modals/Confirm";
import "./Card.css";

const Card = (props) => {
	const auth = useContext(AuthContext);

	// set up headers config
	const config = {
		headers: {
			Authorization: "Bearer " + auth.token
		}
	};

	const [cardData, setCardData] = useState(props.cardData);
	const [editMode, setEditMode] = useState(false);
	const [showFullDetails, setShowFullDetails] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);

	let complete = true;
	let collected = false;
	let excluded = false;
	let variantCount = 0;

	if (!props.parentData && !props.duplicatesParentData) {
		variantCount = cardData.variants.length;
	}

	cardData.accessories.forEach((item) => {
		if (item.owned === false) {
			complete = false;
		}
	});

	if (cardData.owned && cardData.replace) {
		excluded = true;
	}

	const handleUpdates = (newData, newImage, formIsValid, markedComplete) => {
		const stringData = JSON.stringify({ ...cardData, ...newData });
		const formData = new FormData();
		formData.append("newData", stringData);
		formData.append("formIsValid", formIsValid);
		// Also send the customImage in case we need to delete it from the server
		formData.append("customImage", cardData.customImage);
		formData.append("mode", props.mode);
		if (newImage) {
			formData.append("image", newImage);
		}
		// If a variant
		if (props.parentData) {
			axios
				.put(
					process.env.REACT_APP_SERVER_DOMAIN +
						"/api/items/variant/" +
						props.username +
						"/" +
						props.parentData._id +
						"/" +
						cardData._id,
					formData,
					config
				)
				.then((res) => {
					setCardData((prev) => ({ ...prev, ...res.data.newData }));
					props.handleRefresh(res.data.newData, false, true, true, markedComplete);
				});
		} else if (props.duplicatesParentData) {
			// If a duplicate
			axios
				.put(
					process.env.REACT_APP_SERVER_DOMAIN +
						"/api/items/duplicate/" +
						props.username +
						"/" +
						props.duplicatesParentData._id +
						"/" +
						cardData._id,
					formData,
					config
				)
				.then((res) => {
					setCardData((prev) => ({ ...prev, ...res.data.newData }));
					props.handleRefresh(res.data.newData, false, false, true, false, true);
				});
		} else {
			axios
				.put(
					process.env.REACT_APP_SERVER_DOMAIN + "/api/items/" + props.username + "/" + cardData._id,
					formData,
					config
				)
				.then((res) => {
					setCardData((prev) => ({ ...prev, ...res.data.newData }));
					props.handleRefresh(res.data.newData, false, false, true, markedComplete);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	const handleEditClick = () => {
		setEditMode((prev) => !prev);
		if (!props.parentData && !props.duplicatesParentData) {
			if (!props.parentData) {
				props.setTheVariantsData(cardData.variants, cardData);
			}
			if (!props.duplicatesParentData) {
				props.setTheDuplicatesData(cardData.duplicates, cardData);
			}
		}
	};

	const handleShowVariants = () => {
		setEditMode(false);
		props.setTheVariantsData(cardData.variants, cardData);
		props.onShowVariants();
	};

	const handleShowDuplicates = () => {
		setEditMode(false);
		props.setTheDuplicatesData(cardData.duplicates, cardData);
		props.onShowDuplicates();
	};

	const handleSaveClick = (newData, newImage, formIsValid, markedComplete) => {
		if (formIsValid) {
			setEditMode(false);
			handleUpdates(newData, newImage, formIsValid, markedComplete);
			props.showNotification("Your changes have been saved");
		}
	};

	const handleShowConfirm = (e) => {
		e.preventDefault();
		setShowConfirm(true);
	};

	const handleCancelClick = () => {
		setEditMode(false);
	};

	const handleDelete = () => {
		// If a variant
		if (props.parentData) {
			const formData = new FormData();
			formData.append("mode", props.mode);
			formData.append("customImage", cardData.customImage);
			axios
				.put(
					process.env.REACT_APP_SERVER_DOMAIN +
						"/api/items/delete/variant/" +
						props.username +
						"/" +
						props.parentData._id +
						"/" +
						cardData._id,
					formData,
					config
				)
				.then(() => {
					handleCancelClick();
					setShowConfirm(false);
					props.showNotification("Variant deleted successfully");
					props.handleRefresh(cardData, false, true);
				});
		} else if (props.duplicatesParentData) {
			// If duplicate
			const formData = new FormData();
			formData.append("mode", props.mode);
			formData.append("customImage", cardData.customImage);
			axios
				.put(
					process.env.REACT_APP_SERVER_DOMAIN +
						"/api/items/delete/duplicate/" +
						props.username +
						"/" +
						props.duplicatesParentData._id +
						"/" +
						cardData._id,
					formData,
					config
				)
				.then(() => {
					handleCancelClick();
					setShowConfirm(false);
					props.showNotification("Duplicate deleted successfully");
					props.handleRefresh(cardData, false, false, false, false, true);
				});
		} else {
			axios
				.put(
					process.env.REACT_APP_SERVER_DOMAIN + "/api/items/delete/" + props.username + "/" + cardData._id,
					null,
					config
				)
				.then(() => {
					handleCancelClick();
					setShowConfirm(false);
					props.showNotification("Item deleted successfully");
					props.handleRefresh(cardData, false, false);
				});
		}
	};

	const handleImageClick = () => {
		props.handleImageClick(cardData.image, cardData.customImage);
	};

	const handleShowFullDetails = () => {
		setShowFullDetails((prev) => !prev);
		if (!props.parentData && !props.duplicatesParentData) {
			if (!props.parentData) {
				props.setTheVariantsData(cardData.variants, cardData);
			}
			if (!props.duplicatesParentData) {
				props.setTheDuplicatesData(cardData.duplicates, cardData);
			}
		}
	};

	const handleCloseConfirm = () => {
		setShowConfirm(false);
	};

	const showVariantForm = () => {
		setEditMode(false);
		props.showVariantForm();
	};

	const showDuplicateForm = () => {
		setEditMode(false);
		props.showDuplicateForm();
	};

	// Determine the correct image directory and field based on whether customImage has a value
	let imageDir;
	let imageField;
	let usingCustomImage = false;
	if (cardData.customImage && cardData.customImage.length > 0) {
		imageDir = "user/";
		imageField = cardData.customImage;
		usingCustomImage = true;
	} else {
		imageDir = "default/";
		imageField = cardData.image;
	}

	const handleMarkAsOwned = () => {
		if (props.username === "starterdata") {
			props.handleShowUserSignUpForm();
		} else {
			if (props.mode !== "loose") {
				handleMarkAsComplete(true);
			} else {
				handleSaveClick({ ...cardData, owned: true }, false, true);
			}
		}
	};

	const handleMarkAsComplete = (plusOwned) => {
		const newAccessories = [...props.cardData.accessories].map((item) => {
			item.owned = true;
			return item;
		});
		if (plusOwned) {
			handleSaveClick({ ...cardData, owned: true, accessories: newAccessories }, false, true, true);
		} else {
			handleSaveClick({ ...cardData, accessories: newAccessories }, false, true, true);
		}
	};

	const licenseeName = (licensee) => {
		switch (licensee) {
			case "clipper":
				return "Clipper";
			case "glasslite":
				return "Glasslite";
			case "kenner":
				return "Kenner";
			case "lili-ledy":
				return "Lili Ledy";
			case "meccano":
				return "Meccano";
			case "model-trem":
				return "Model Trem";
			case "pbp":
				return "PBP";
			case "palitoy":
				return "Palitoy";
			case "poch":
				return "Poch";
			case "takara":
				return "Takara";
			case "toltoys":
				return "Toltoys";
			case "top-toys":
				return "TopToys";
			case "uzay":
				return "Uzay";
			default:
		}
	};

	const factoryName = (licensee) => {
		switch (licensee) {
			case "kader":
				return "Kader";
			case "unitoy":
				return "Unitoy";
			case "smile":
				return "Smile";
			case "universal":
				return "Universal";
			case "jetta":
				return "Jetta";
			case "qualidux":
				return "Qualidux";
			case "great-shing":
				return "Great Shing";
			case "taiwan":
				return "Taiwan";
			default:
		}
	};

	return (
		<div
			className={
				"card" +
				(cardData.owned ? (excluded ? " excluded" : complete ? " collected" : " not-complete") : "") +
				(props.compact ? " compact" : "")
			}
		>
			{showConfirm && (
				<Confirm onCancel={handleCloseConfirm} onOk={handleDelete} text="Are you sure?" yes="Yes" no="Cancel"></Confirm>
			)}
			{editMode && (
				<EditCard
					onDelete={handleShowConfirm}
					cardData={cardData}
					imageUrl={process.env.REACT_APP_IMAGES_DOMAIN + "/images/" + imageDir + imageField}
					usingCustomImage={usingCustomImage}
					onSave={handleSaveClick}
					onCancel={handleCancelClick}
					collected={collected}
					showVariants={handleShowVariants}
					showVariantForm={showVariantForm}
					showDuplicates={handleShowDuplicates}
					showDuplicateForm={showDuplicateForm}
					closeEditMode={handleEditClick}
					parentData={props.parentData}
					duplicatesParentData={props.duplicatesParentData}
					className="edit-card"
					mode={props.mode}
					scope={props.scope}
					setTheVariantsData={props.setTheVariantsData}
					setTheDuplicatesData={props.setTheDuplicatesData}
				></EditCard>
			)}
			{showFullDetails && (
				<FullDetails
					showFullDetails={showFullDetails}
					data={cardData}
					closeFullDetails={handleShowFullDetails}
					complete={complete}
					parentData={props.parentData}
					duplicatesParentData={props.duplicatesParentData}
					showVariants={handleShowVariants}
					showDuplicates={handleShowDuplicates}
					showEditMode={handleEditClick}
					mode={props.mode}
					scope={props.scope}
					imageUrl={process.env.REACT_APP_IMAGES_DOMAIN + "/images/" + imageDir + "big/" + imageField}
					onImageClick={handleImageClick}
					sharedLink={props.sharedLink}
				></FullDetails>
			)}
			<div className="card-name" style={{ display: props.compact ? "none" : "flex" }}>
				<h2 onClick={handleShowFullDetails}>{cardData.name}</h2>
				<div className="card-controls">
					<i className="edit-icon fa-solid fa-eye" onClick={handleShowFullDetails}></i>
					{auth.isLoggedIn && !props.sharedLink && (
						<i className="edit-icon fa-solid fa-pen-to-square" onClick={handleEditClick}></i>
					)}
				</div>
			</div>
			<div className="card-bottom">
				<div className="card-left">
					<div className="card-photo">
						<img
							src={process.env.REACT_APP_IMAGES_DOMAIN + "/images/" + imageDir + imageField}
							alt={cardData.name}
							onClick={handleShowFullDetails}
						/>
					</div>
					<div className="card-info">
						<div className="card-name" style={{ display: !props.compact ? "none" : "block" }}>
							<div className="compact-header">
								<h2 onClick={handleShowFullDetails}>{cardData.name}</h2>
								<div className="card-controls">
									<i className="edit-icon fa-solid fa-eye" onClick={handleShowFullDetails}></i>
									{auth.isLoggedIn && !props.sharedLink && (
										<i className="edit-icon fa-solid fa-pen-to-square" onClick={handleEditClick}></i>
									)}
								</div>
							</div>
						</div>
						<div className={"check " + (cardData.owned ? "yes" : "no")}>
							<div>
								<b>Owned: </b>
								<span>{cardData.owned ? "Yes" : "No"}</span>
							</div>
						</div>
						{!cardData.owned && (
							<button className="basic" onClick={handleMarkAsOwned}>
								<i className="fa-solid fa-check"></i> Mark owned
							</button>
						)}
						{props.mode === "loose" && (
							<div className={"check " + (cardData.owned ? (complete ? "yes" : "no") : "hidden")}>
								<div>
									<b>Complete: </b>
									<span>{cardData.owned && (complete ? "Yes" : "No")}</span>
								</div>
							</div>
						)}
						{cardData.owned && complete === false && (
							<button className="basic" onClick={handleMarkAsComplete}>
								<i className="fa-solid fa-check"></i> Mark complete
							</button>
						)}
					</div>
				</div>
				<div className="card-right">
					<div className="card-info">
						{cardData.duplicates && cardData.duplicates.length > 0 && (
							<div>
								<h4>Quantity owned: <span>{cardData.duplicates.length + 1}</span></h4>
								<p>
									<span className="view-duplicates-from-card-link" onClick={handleShowDuplicates}>
										View duplicates
									</span>
								</p>
							</div>
						)}
						{cardData.variant && (
							<div>
								<h4>Variant info:</h4> <p>{cardData.variant}</p>
							</div>
						)}
						{cardData.coo && (
							<div>
								<h4>COO:</h4> <p>{cardData.coo}</p>
							</div>
						)}
						{cardData.licensee && cardData.licensee !== "kenner" && (
							<div>
								<h4>Licensee:</h4> <p>{licenseeName(cardData.licensee)}</p>
							</div>
						)}
						{cardData.factory && (
							<div>
								<h4>Factory:</h4> <p>{factoryName(cardData.factory)}</p>
							</div>
						)}

						<h4>Accessories:</h4>
						{cardData.accessories.length > 0 ? (
							cardData.accessories.map((item) => (
								<p className="accessory" key={Math.random()}>
									<span>
										<i className={"fa-solid " + (item.owned ? "fa-check" : "fa-x")}></i> {item.name}
									</span>
								</p>
							))
						) : (
							<p>None</p>
						)}
					</div>
					{variantCount > 0 && (
						<button className="basic" onClick={handleShowVariants}>
							{variantCount} Variant{variantCount > 1 && "s"}
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default Card;
