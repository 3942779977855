import React from "react";

import CompleteFilter from "./CompleteFilter";
import ExcludedFilter from "./ExcludedFilter";
import ConditionFilter from "./ConditionFilter";
import GradeFilter from "./GradeFilter";
import CompanyFilter from "./CompanyFilter";
import CardbackOwnedFilter from "./CardbackOwnedFilter";
import Filter from "../../elements/Filter";

const OwnedFilter = (props) => {
	return (
		<>
			<Filter
				className="owned-filter"
				heading="Owned"
				state={props.owned}
				onChange={props.onChange}
				options={[
					{ id: "owned", label: "Yes" },
					{ id: "not-owned", label: "No" },
					{ id: "both-owned", label: "Both" }
				]}
			></Filter>
			{props.owned === "owned" && (
				<>
					{props.mode === "loose" && (
						<CompleteFilter complete={props.complete} onChange={props.onChange}></CompleteFilter>
					)}
					{(props.mode === "loose" || props.mode === "moc") && (
						<ConditionFilter
							condition={props.condition}
							onChange={props.onChange}
							showConditionOptions={props.showConditionOptions}
							handleShowHideOptions={props.handleShowHideOptions}
						></ConditionFilter>
					)}
					{(props.mode === "loose-graded" || props.mode === "moc-graded") && (
						<>
							<CompanyFilter
								company={props.company}
								onChange={props.onChange}
								showCompanyOptions={props.showCompanyOptions}
								handleShowHideOptions={props.handleShowHideOptions}
							></CompanyFilter>
							<GradeFilter
								grade={props.grade}
								onChange={props.onChange}
								showGradeOptions={props.showGradeOptions}
								handleShowHideOptions={props.handleShowHideOptions}
							></GradeFilter>
						</>
					)}
					{(props.scope === "base") && (props.mode === "moc" || props.mode === "moc-graded") && (
						<CardbackOwnedFilter
							cardbackOwned={props.cardbackOwned}
							onChange={props.onChange}
							showCardbackOwnedOptions={props.showCardbackOwnedOptions}
							handleShowHideOptions={props.handleShowHideOptions}
						></CardbackOwnedFilter>
					)}
					<ExcludedFilter exclude={props.exclude} onChange={props.onChange}></ExcludedFilter>
				</>
			)}
		</>
	);
};

export default OwnedFilter;
