import React, { useContext, useState } from "react";

import logo from '../../images/swcl-logo-d.png';
import { AuthContext } from "../../context/auth-context";
import Search from "../controls/Search";
import "./Header.css";
import TabsContainer from "../controls/TabsContainer";

const Header = (props) => {
	const auth = useContext(AuthContext);
	const [showSearchBox, setShowSearchBox] = useState(false);

	const handleResetFilters = () => {
		props.handleFullReset();
		props.handleShowNotification("Filters reset successfully");
	};

	const handleShowSearchBox = () => {
		setShowSearchBox((prev) => !prev);
	};

	return (
		<>
			{props.sharedLink && (
				<div className="currently-viewing">
					Currently viewing <span className="param-user">{props.paramUser}</span>'s collection
				</div>
			)}
			<header className="top-header">
				<div className="logo" onClick={handleResetFilters}>
					<img src={logo} alt="Star Wars Checklist" />
				</div>

				<div className="top-header-right">
					<div className="collection-stats">
						{!auth.isLoggedIn || props.username === "starterdata" ? (
							<>
								<button className="sign-up-button" onClick={props.showUserSignUpForm}>
									Sign up
								</button>
								<p className="log-in-link" onClick={props.onShowLoginForm}>
									Log in
								</p>
							</>
						) : (
							<>
								<div>
									{props.sharedLink ? (
										<a className="username-link" href={`/${props.username}`}>
											View your collection
										</a>
									) : (
										<div className="profile-links" onClick={props.showProfile}>
											<div className="username-link" href="/">
												{props.username}
											</div>{" "}
											<i className="fa fa-solid fa-gear"></i>
										</div>
									)}
								</div>
								<p className="log-in-link" onClick={props.onLogout}>
									Log out
								</p>
							</>
						)}
						<i className="fa fa-solid fa-search" onClick={handleShowSearchBox}></i>
					</div>

					<div className={`menu-search-cont ${showSearchBox && "visible"}`}>
						<Search onSearch={props.handleSearch} onClear={props.handleClearSearch} value={props.searchInput}></Search>
					</div>
				</div>

				<TabsContainer mode={props.mode} onTabClick={props.onTabClick}></TabsContainer>
			</header>
		</>
	);
};

export default Header;
