import React, { useContext, useState } from "react";

import { AuthContext } from "../../context/auth-context";
import Overlay from "../elements/Overlay";
import "./FullDetails.css";
import Spinner from "../elements/Spinner";

const FullDetails = (props) => {
	const [imageLoaded, setImageLoaded] = useState(false);

	const auth = useContext(AuthContext);

	const debutCardbackName = (cardback) => {
		switch (cardback) {
			case "sw-12":
				return "Star Wars 12-back";
			case "sw-20":
				return "Star Wars 20-back";
			case "sw-21":
				return "Star Wars 21-back";
			case "esb-31":
				return "The Empire Strikes Back 31-back";
			case "esb-32":
				return "The Empire Strikes Back 32-back";
			case "esb-41":
				return "The Empire Strikes Back 41-back";
			case "esb-45":
				return "The Empire Strikes Back 45-back";
			case "esb-47":
				return "The Empire Strikes Back 47-back";
			case "esb-48":
				return "The Empire Strikes Back 48-back";
			case "rotj-65":
				return "Return of the Jedi 65-back";
			case "rotj-77":
				return "Return of the Jedi 77-back";
			case "rotj-79":
				return "Return of the Jedi 79-back";
			case "potf-92":
				return "Power of the Force 92-back";
			default:
		}
	};

	const licenseeName = (licensee) => {
		switch (licensee) {
			case "clipper":
				return "Clipper";
			case "glasslite":
				return "Glasslite";
			case "kenner":
				return "Kenner";
			case "lili-ledy":
				return "Lili Ledy";
			case "meccano":
				return "Meccano";
			case "model-trem":
				return "Model Trem";
			case "pbp":
				return "PBP";
			case "palitoy":
				return "Palitoy";
			case "poch":
				return "Poch";
			case "takara":
				return "Takara";
			case "toltoys":
				return "Toltoys";
			case "top-toys":
				return "TopToys";
			case "uzay":
				return "Uzay";
			default:
		}
	};

	const factoryName = (licensee) => {
		switch (licensee) {
			case "kader":
				return "Kader";
			case "unitoy":
				return "Unitoy";
			case "smile":
				return "Smile";
			case "universal":
				return "Universal";
			case "jetta":
				return "Jetta";
			case "qualidux":
				return "Qualidux";
			case "great-shing":
				return "Great Shing";
			case "taiwan":
				return "Taiwan";
			default:
		}
	};

	const handleShowVariants = (e) => {
		e.preventDefault();
		props.showVariants();
	};

	const handleShowDuplicates = (e) => {
		e.preventDefault();
		props.showDuplicates();
	};

	const handleImgLoad = () => {
		setImageLoaded(true);
	};

	return (
		<Overlay onClose={props.closeFullDetails} className="full-details">
			<style>
				{`
				body{
					overflow: hidden;
				}
				`}
			</style>
			<h2
				className={"full-details-heading" + (!props.complete || props.data.replace || !props.data.owned ? " red" : "")}
			>
				<div>
					<span className="tag">
						{props.mode === "loose"
							? "Loose"
							: props.mode === "loose-graded"
							? "Loose Graded"
							: props.mode === "moc"
							? "MOC/MISB"
							: "MOC/MISB Graded"}
					</span>
					<span className="tag">
						{props.scope === "base" ||
						(props.parentData && props.parentData.scope === "base") ||
						(props.duplicatesParentData && props.duplicatesParentData.scope === "base")
							? "Figure"
							: props.scope === "creatures" ||
							  (props.parentData && props.parentData.scope === "creatures") ||
							  (props.duplicatesParentData && props.duplicatesParentData.scope === "creatures")
							? "Creature"
							: props.scope === "vehicles" ||
							  (props.parentData && props.parentData.scope === "vehicles") ||
							  (props.duplicatesParentData && props.duplicatesParentData.scope === "vehicles")
							? "Vehicle"
							: props.scope === "playsets" ||
							  (props.parentData && props.parentData.scope === "playsets") ||
							  (props.duplicatesParentData && props.duplicatesParentData.scope === "playsets")
							? "Playset"
							: "Mini-rig"}
					</span>
					{props.parentData && <span className="tag">Variant</span>}
					{props.duplicatesParentData && <span className="tag">Duplicate</span>}
					<br></br>
					{props.data.name}
				</div>
				<div className="mobile-close" onClick={props.closeFullDetails}>
					<i className="fa fa-solid fa-close"></i>
				</div>
				{auth.isLoggedIn && !props.sharedLink && (
					<div className="full-details-edit-cont">
						<button onClick={props.showEditMode}>Edit</button>
					</div>
				)}
			</h2>

			<main>
				<section className="full-details-left">
					{!imageLoaded && <Spinner className="small absolute"></Spinner>}
					<figure onClick={props.onImageClick}>
						<img onLoad={handleImgLoad} src={props.imageUrl} alt="" />
					</figure>
				</section>
				<section className="full-details-center">
					<div className="full-details-column">
						<div>
							<p>Name</p>
							<p>{props.data.name}</p>
						</div>
						<div>
							<p>Release year</p>
							<p>{props.data.yearReleased}</p>
						</div>
						{(props.data.scope === "base" || (props.parentData && props.parentData.scope === "base")) && (
							<div>
								<p>Debut cardback</p>
								<p>{debutCardbackName(props.data.debutCardback)}</p>
							</div>
						)}
						{(props.mode === "moc" || props.mode === "moc-graded") &&
							(props.data.scope === "base" || (props.parentData && props.parentData.scope === "base")) && (
								<div>
									<p>Cardback owned</p>
									<p>
										{debutCardbackName(props.data.cardbackOwned) ? debutCardbackName(props.data.cardbackOwned) : "-"}
									</p>
								</div>
							)}
						<div>
							<p>Variant info</p>
							<p>{props.data.variant ? props.data.variant : "-"}</p>
						</div>
						{props.data.owned && (
							<div>
								<p>COO</p>
								<p>{props.data.coo ? props.data.coo : "-"}</p>
							</div>
						)}
						{props.data.owned && (
							<div>
								<p>Licensee</p>
								<p>{licenseeName(props.data.licensee) ? licenseeName(props.data.licensee) : "-"}</p>
							</div>
						)}
						{props.data.owned && (
							<div>
								<p>Factory</p>
								<p>{factoryName(props.data.factory) ? factoryName(props.data.factory) : "-"}</p>
							</div>
						)}
						<div>
							<p>Accessories</p>
							<ol>
								{props.data.accessories.length > 0
									? props.data.accessories.map((item, index) => {
											return (
												<li key={index}>
													{item.name} (
													{item.owned ? (
														<span className="full-details-owned">owned</span>
													) : (
														<span className="full-details-not-owned">not owned</span>
													)}
													)
												</li>
											);
									  })
									: "None"}
							</ol>
						</div>
						{!props.parentData && !props.duplicatesParentData && (
							<div>
								<p>Variants</p>
								<p>
									{props.data.variants.length > 0 ? (
										<span className="full-details-variant-link" onClick={handleShowVariants}>
											{props.data.variants.length} variant{props.data.variants.length > 1 ? "s" : ""}
										</span>
									) : (
										"None"
									)}
								</p>
							</div>
						)}
					</div>
				</section>
				<section className="full-details-right">
					<div className="full-details-column">
						<div>
							<p>Owned</p>
							{props.data.owned ? (
								<p className="full-details-owned">Yes</p>
							) : (
								<p className="full-details-not-owned">No</p>
							)}
						</div>
						{props.data.owned && props.mode === "loose" && (
							<div>
								<p>Status</p>
								{props.data.owned &&
									(props.complete ? (
										<p className="full-details-owned">Complete</p>
									) : (
										<p className="full-details-not-owned">Incomplete</p>
									))}
							</div>
						)}
						{!props.duplicatesParentData && props.data.owned && (
							<div>
								<p>Quantity owned</p>
								<p>
									{props.data.duplicates && props.data.duplicates.length + 1}
									<span className="full-details-variant-link" onClick={handleShowDuplicates}>
										{props.data.duplicates && props.data.duplicates.length > 0 && (
											<>
												<br></br>View duplicates
											</>
										)}
									</span>
								</p>
							</div>
						)}
						{props.data.owned && (
							<div>
								<p>Date purchased</p>
								<p>{props.data.yearPurchased ? props.data.yearPurchased : "-"}</p>
							</div>
						)}
						{props.data.owned && (
							<div>
								<p>Price paid</p>
								<p>{props.data.pricePaid ? props.data.pricePaid : "-"}</p>
							</div>
						)}
						{props.data.owned && (
							<div>
								<p>Purchased from</p>
								<p>{props.data.whereBought ? props.data.whereBought : "-"}</p>
							</div>
						)}
						{(props.mode === "loose" || props.mode === "moc") && props.data.owned && (
							<div>
								<p>Condition</p>
								<p>{props.data.condition ? props.data.condition : "-"}</p>
							</div>
						)}
						{(props.mode === "loose-graded" || props.mode === "moc-graded") && (
							<>
								<div>
									<p>Grading company</p>
									<p>{props.data.company ? props.data.company : "-"}</p>
								</div>
								<div>
									<p>Grade</p>
									<p>{props.data.grade ? props.data.grade : "-"}</p>
								</div>
								<div>
									<p>Grade qualifier</p>
									<p>{props.data.gradeQualifier ? props.data.gradeQualifier : "-"}</p>
								</div>
								{props.mode === "moc-graded" && (
									<div>
										<p>Sub grades</p>
										<p>{props.data.subGrades ? props.data.subGrades : "-"}</p>
									</div>
								)}
								<div>
									<p>Date graded</p>
									<p>{props.data.dateGraded ? props.data.dateGraded : "-"}</p>
								</div>
							</>
						)}
						{!props.parentData && props.data.owned && !props.duplicatesParentData && (
							<div>
								<p>Excluded from count</p>
								<p>{props.data.replace ? <p className="full-details-not-owned">Yes</p> : "No"}</p>
							</div>
						)}
						<div>
							<p>Notes</p>
							<p>{props.data.notes ? props.data.notes : "-"}</p>
						</div>
					</div>
				</section>
			</main>
		</Overlay>
	);
};

export default FullDetails;
