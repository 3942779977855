import React from "react";

const ConditionFilter = (props) => {
	const handleShowConditionOptions = () => {
		props.handleShowHideOptions("condition");
	};

	return (
		<div className="filter condition-filter">
			<div className="filter-heading">Condition</div>
			<div className="filter-options show-options">
				<div
					className={`options-show-hide ${props.showConditionOptions ? "active" : ""}`}
					onClick={handleShowConditionOptions}
				>
					<i className={"fa-solid " + (props.showConditionOptions ? "fa-chevron-up" : "fa-chevron-down")}></i>
				</div>
			</div>
			{props.showConditionOptions && (
				<div className="filter-options-cont">
					<div className="filter-options-section">
						<div className="options-section-title">Condition:</div>
						<div
							id="condition-1"
							className={`option-item${props.condition.includes("condition-1") ? " active" : ""}`}
							onClick={props.onChange}
						>
							1
						</div>
						<div
							id="condition-2"
							className={`option-item${props.condition.includes("condition-2") ? " active" : ""}`}
							onClick={props.onChange}
						>
							2
						</div>
						<div
							id="condition-3"
							className={`option-item${props.condition.includes("condition-3") ? " active" : ""}`}
							onClick={props.onChange}
						>
							3
						</div>
						<div
							id="condition-4"
							className={`option-item${props.condition.includes("condition-4") ? " active" : ""}`}
							onClick={props.onChange}
						>
							4
						</div>
						<div
							id="condition-5"
							className={`option-item${props.condition.includes("condition-5") ? " active" : ""}`}
							onClick={props.onChange}
						>
							5
						</div>
						<div
							id="condition-6"
							className={`option-item${props.condition.includes("condition-6") ? " active" : ""}`}
							onClick={props.onChange}
						>
							6
						</div>
						<div
							id="condition-7"
							className={`option-item${props.condition.includes("condition-7") ? " active" : ""}`}
							onClick={props.onChange}
						>
							7
						</div>
						<div
							id="condition-8"
							className={`option-item${props.condition.includes("condition-8") ? " active" : ""}`}
							onClick={props.onChange}
						>
							8
						</div>
						<div
							id="condition-9"
							className={`option-item${props.condition.includes("condition-9") ? " active" : ""}`}
							onClick={props.onChange}
						>
							9
						</div>
						<div
							id="condition-10"
							className={`option-item${props.condition.includes("condition-10") ? " active" : ""}`}
							onClick={props.onChange}
						>
							10
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ConditionFilter;
