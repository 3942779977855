import React from "react";

import "./Footer.css";

const Footer = () => {
	return (
		<footer className="footer">
			Questions, comments or concerns? Contact me at <a href="mailto:chris@starwarschecklist.com">chris@starwarschecklist.com</a>
			<br />
			&copy; Copyright {new Date().getFullYear()} all rights reserved.
		</footer>
	);
};

export default Footer;
