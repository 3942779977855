import React from "react";

import Filter from "../../elements/Filter";

const HasVariantsFilter = (props) => {
  return (
		<Filter
			className="has-variants-filter"
			heading="Has variants"
			state={props.hasVariants}
			onChange={props.onChange}
			options={[
				{ id: "yes-has-variants", label: "Yes" },
				{ id: "no-has-variants", label: "No" },
				{ id: "both-has-variants", label: "Both" }
			]}
		></Filter>
	);
}

export default HasVariantsFilter;