import React from "react";

import Filter from "../../elements/Filter";

const ExcludedFilter = (props) => {
	return (
		<Filter
			className="excluded-filter"
			heading="Excluded"
			state={props.exclude}
			onChange={props.onChange}
			options={[
				{ id: "yes-exclude", label: "Yes" },
				{ id: "no-exclude", label: "No" },
				{ id: "both-exclude", label: "Both" }
			]}
		></Filter>
	);
};

export default ExcludedFilter;
