import React, { useState, useCallback, useReducer, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import Input from "../../elements/Input";
import { VALIDATOR_EMAIL, VALIDATOR_MINLENGTH, VALIDATOR_USERNAME } from "../../../utils/validators";
import formReducer from "../../../utils/formReducer";
import Overlay from "../../elements/Overlay";
import Spinner from "../../elements/Spinner";

const UserSignUp = (props) => {
	const [formState, dispatch] = useReducer(formReducer, {
		inputs: {
			email: { value: "", isValid: false },
			username: { value: "", isValid: false },
			password: { value: "", isValid: false }
		},
		isValid: false
	});

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const [showPasswordError, setShowPasswordError] = useState(false);
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);

	useEffect(() => {
		if (formSubmitted) {
			if (props.signupError) {
				setShowSpinner(false);
			} else {
				setShowSpinner(true);
			}
		}
	}, [props.signupError]);

	const onSaveClick = (e) => {
		setShowSpinner(true);
		setFormSubmitted(true);
		e.preventDefault();
		const newUserData = {
			firstName: firstName,
			lastName: lastName,
			email: email,
			username: username,
			password: password
		};
		props.handleSaveClick(newUserData);
	};

	const handleInputChange = (e) => {
		const id = e.target.id;
		const val = e.target.value;
		switch (id) {
			case "first-name":
				setFirstName(val);
				break;
			case "last-name":
				setLastName(val);
				break;
			default:
		}
	};

	const inputHandler = useCallback((id, value, isValid, matchAgainst) => {
		dispatch({
			type: "INPUT_CHANGE",
			value: value,
			isValid: isValid,
			inputId: id
		});
		switch (id) {
			case "email":
				setEmail(value);
				break;
			case "username":
				setUsername(value);
				break;
			case "password":
				setShowPasswordError(value !== matchAgainst);
				setPassword(value);
				break;
			case "password-confirm":
				setShowPasswordError(value !== matchAgainst);
				setPasswordConfirm(value);
				break;
			default:
		}
	}, []);

	const handleOnCancel = () => {
		if (!showSpinner) {
			props.onCancel();
		}
	};

	function onChange(value) {
		console.log("Captcha value:", value);
	}

	return (
		<Overlay onClose={handleOnCancel} className="user-sign-up-form">
			<h2 className="sign-up-heading">Create an account</h2>
			<form action="" onSubmit={onSaveClick}>
				<div className="form-column form-column-single">
					<span className="error-text"></span>
					{props.signupError && <span className="error-text"> {props.signupError}</span>}
					<Input element="input" type="text" label="First name" id="first-name" onChange={handleInputChange} />
					<Input element="input" type="text" label="Last name" id="last-name" onChange={handleInputChange} />
					<Input
						element="input"
						type="text"
						label="Email"
						id="email"
						value=""
						validate
						isValidStart={false}
						validators={[VALIDATOR_EMAIL()]}
						errorText=" Please enter a valid email"
						onInput={inputHandler}
					/>
					<Input
						element="input"
						type="text"
						label="Desired username"
						id="username"
						value=""
						validate
						isValidStart={false}
						validators={[VALIDATOR_USERNAME()]}
						errorText=" Required and may consist only of letters, numbers, dashes or underscores"
						signupError={props.signupError}
						onInput={inputHandler}
					/>
					<Input
						element="input"
						type="password"
						label="Password"
						id="password"
						value=""
						matchAgainst={passwordConfirm}
						validate
						isValidStart={false}
						validators={[VALIDATOR_MINLENGTH(5)]}
						errorText=" Must be at least 5 characters long"
						onInput={inputHandler}
					/>
					<Input
						element="input"
						type="password"
						label="Confirm password"
						id="password-confirm"
						value=""
						matchAgainst={password}
						validate
						isValidStart={false}
						validators={[]}
						errorText=" Passwords don't match"
						showPasswordError={showPasswordError}
						onInput={inputHandler}
					/>
					{/* <ReCAPTCHA sitekey="6Lec9VcpAAAAANGFuT1f5n2nHmh0DNr7_9XC1QfF" onChange={onChange} /> */}
					<div className="edit-save-cancel">
						{showSpinner && (
							<div className="signup-spinner-cont">
								<p>Hyperdrive charging, hold tight...</p> <Spinner className="small"></Spinner>
							</div>
						)}
						<div>
							<input type="submit" value="Submit" disabled={!formState.isValid || showPasswordError || showSpinner} />
							<button className="cancel" onClick={props.onCancel} disabled={showSpinner}>
								Cancel
							</button>
						</div>
					</div>
					<p className="form-message">
						Already have an account?{" "}
						<span className="form-link" onClick={props.loginSwitch}>
							Log in here.
						</span>
					</p>
				</div>
			</form>
		</Overlay>
	);
};

export default UserSignUp;
