import React, { useState, useCallback, useReducer } from "react";
import { useSearchParams } from "react-router-dom";

import Input from "../../elements/Input";
import { VALIDATOR_MINLENGTH } from "../../../utils/validators";
import formReducer from "../../../utils/formReducer";
import Overlay from "../../elements/Overlay";

const ResetPassword = (props) => {
  const [queryString, ] = useSearchParams();

	const [formState, dispatch] = useReducer(formReducer, {
		inputs: {
			password: { value: "", isValid: false }
		},
		isValid: false
	});

	// field states
	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const [showPasswordError, setShowPasswordError] = useState(false);

	const onSubmitClick = (e) => {
		e.preventDefault();
		const userData = {
			password: password,
      username: queryString.get("username"),
      token: queryString.get("token")
		};
		props.handleSaveClick(userData);
	};

	const inputHandler = useCallback((id, value, isValid, matchAgainst) => {
		dispatch({
			type: "INPUT_CHANGE",
			value: value,
			isValid: isValid,
			inputId: id
		});
		switch (id) {
			case "password":
				setShowPasswordError(value !== matchAgainst);
				setPassword(value);
				break;
			case "password-confirm":
				setShowPasswordError(value !== matchAgainst);
				setPasswordConfirm(value);
				break;
			default:
		}
	}, []);

	return (
		<Overlay onClose={props.onCancel} className="user-sign-up-form">
			<h2 className="sign-up-heading">Password reset</h2>
			<form action="" onSubmit={onSubmitClick}>
				<div className="form-column form-column-single">
					<span className="error-text">{props.resetPwFormError}</span>
					<p>Choose a new password</p>
					<Input
						element="input"
						type="password"
						label="Password"
						id="password"
						value=""
						matchAgainst={passwordConfirm}
						validate
						isValidStart={false}
						validators={[VALIDATOR_MINLENGTH(5)]}
						errorText=" Must be at least 5 characters long"
						onInput={inputHandler}
					/>
					<Input
						element="input"
						type="password"
						label="Confirm password"
						id="password-confirm"
						value=""
						matchAgainst={password}
						validate
						isValidStart={false}
						validators={[]}
						errorText=" Passwords don't match"
						showPasswordError={showPasswordError}
						onInput={inputHandler}
					/>
					<div className="edit-save-cancel">
						<div>
							<input type="submit" value="Save" disabled={!formState.isValid || showPasswordError} />
							<button className="cancel" onClick={props.onCancel}>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</form>
		</Overlay>
	);
};

export default ResetPassword;
