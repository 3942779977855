import React from "react";

const CardbackOwnedFilter = (props) => {
	const handleShowCardbackOwnedOptions = () => {
		props.handleShowHideOptions("cardbackOwned");
	};

	return (
		<div className="filter cardback-owned-filter">
			<div className="filter-heading">Cardback owned</div>
			<div className="filter-options show-options">
				<div
					className={`options-show-hide ${props.showCardbackOwnedOptions ? "active" : ""}`}
					onClick={handleShowCardbackOwnedOptions}
				>
					<i className={"fa-solid " + (props.showCardbackOwnedOptions ? "fa-chevron-up" : "fa-chevron-down")}></i>
				</div>
			</div>
			{props.showCardbackOwnedOptions && (
				<div className="filter-options-cont">
					<div className="filter-options-section">
						<div className="options-section-title">SW:</div>
						<div
							id="cb-own-sw-12"
							className={`option-item${props.cardbackOwned.includes("cb-own-sw-12") ? " active" : ""}`}
							onClick={props.onChange}
						>
							12
						</div>
						<div
							id="cb-own-sw-20"
							className={`option-item${props.cardbackOwned.includes("cb-own-sw-20") ? " active" : ""}`}
							onClick={props.onChange}
						>
							20
						</div>
						<div
							id="cb-own-sw-21"
							className={`option-item${props.cardbackOwned.includes("cb-own-sw-21") ? " active" : ""}`}
							onClick={props.onChange}
						>
							21
						</div>
					</div>
					<div className="filter-options-section">
						<div className="options-section-title">ESB:</div>
						<div
							id="cb-own-esb-31"
							className={`option-item${props.cardbackOwned.includes("cb-own-esb-31") ? " active" : ""}`}
							onClick={props.onChange}
						>
							31
						</div>
						<div
							id="cb-own-esb-32"
							className={`option-item${props.cardbackOwned.includes("cb-own-esb-32") ? " active" : ""}`}
							onClick={props.onChange}
						>
							32
						</div>
						<div
							id="cb-own-esb-41"
							className={`option-item${props.cardbackOwned.includes("cb-own-esb-41") ? " active" : ""}`}
							onClick={props.onChange}
						>
							41
						</div>
						<div
							id="cb-own-esb-45"
							className={`option-item${props.cardbackOwned.includes("cb-own-esb-45") ? " active" : ""}`}
							onClick={props.onChange}
						>
							45
						</div>
						<div
							id="cb-own-esb-47"
							className={`option-item${props.cardbackOwned.includes("cb-own-esb-47") ? " active" : ""}`}
							onClick={props.onChange}
						>
							47
						</div>
						<div
							id="cb-own-esb-48"
							className={`option-item${props.cardbackOwned.includes("cb-own-esb-48") ? " active" : ""}`}
							onClick={props.onChange}
						>
							48
						</div>
					</div>
					<div className="filter-options-section">
						<div className="options-section-title">RotJ:</div>
						<div
							id="cb-own-rotj-65"
							className={`option-item${props.cardbackOwned.includes("cb-own-rotj-65") ? " active" : ""}`}
							onClick={props.onChange}
						>
							65
						</div>
						<div
							id="cb-own-rotj-77"
							className={`option-item${props.cardbackOwned.includes("cb-own-rotj-77") ? " active" : ""}`}
							onClick={props.onChange}
						>
							77
						</div>
						<div
							id="cb-own-rotj-79"
							className={`option-item${props.cardbackOwned.includes("cb-own-rotj-79") ? " active" : ""}`}
							onClick={props.onChange}
						>
							79
						</div>
					</div>
					<div className="filter-options-section">
						<div className="options-section-title">PotF:</div>
						<div
							id="cb-own-potf-92"
							className={`option-item${props.cardbackOwned.includes("cb-own-potf-92") ? " active" : ""}`}
							onClick={props.onChange}
						>
							92
						</div>
					</div>
					<div className="filter-options-section">
					<div className="options-section-title">Other:</div>
						<div
							id="cb-own-tri-logo"
							className={`option-item${props.cardbackOwned.includes("cb-own-tri-logo") ? " active" : ""}`}
							onClick={props.onChange}
						>
							Tri-logo
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default CardbackOwnedFilter;
