import React from "react";

import "./Notification.css";

const Notification = (props) => {
	return (
		<div className={"notification-cont" + (props.active ? " active" : "")}>
			<div className="notification">{props.message}</div>
		</div>
	);
};

export default Notification;
