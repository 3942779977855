import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import Container from "../structures/Container";
import "./Variants.css";

const Duplicates = (props) => {
	useEffect(() => {
		if (props.duplicatesData.length === 0) {
			props.onShowDuplicates();
		}
	}, [props.duplicatesData]);

	const handleShowDuplicateForm = () => {
		props.showDuplicateForm();
	}

	const content = (
		<div className="variants-overlay">
			<style>
				{`
				body{
					overflow: hidden;
				}
				`}
			</style>
			<div className="variant-close">
				<div onClick={props.onShowDuplicates}>
					<i className="fa-solid fa-arrow-left"></i> Back
				</div>
				<i className="fa-solid fa-close" onClick={props.onShowDuplicates}></i>
			</div>
			<h2 className="variants-heading">
				<div>
					{props.duplicatesData.length} {props.duplicatesParentData.name} duplicate
					{props.duplicatesData.length > 1 && "s"}
					<br />
					<button onClick={handleShowDuplicateForm}>Add a duplicate</button>
				</div>
			</h2>
			<Container
				data={props.duplicatesData}
				duplicatesParentData={props.duplicatesParentData}
				compact={false}
				mode={props.mode}
				handleImageClick={props.handleImageClick}
				username={props.username}
				onShowDuplicates={props.handleShowDuplicates}
				handleRefresh={props.handleRefresh}
				showNotification={props.showNotification}
				setTheVariantsData={props.setTheVariantsData}
				setTheDuplicatesData={props.setTheDuplicatesData}
			></Container>
		</div>
	);

	return ReactDOM.createPortal(content, document.getElementById("overlay-hook"));
};

export default Duplicates;
