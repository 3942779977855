import React from "react";

import "./Filter.css";

const Filter = (props) => {
	return (
		<div className={`filter ` + props.className}>
			<div className="filter-heading">{props.heading}</div>
			<div className="filter-options">
				{props.options.map((item, index) => (
					<div id={item.id} className={props.state === item.id ? "active" : ""} onClick={props.onChange} key={index}>
						{item.label}
					</div>
				))}
			</div>
		</div>
	);
};

export default Filter;
