import React, { useState } from "react";

import Overlay from "../elements/Overlay";
import "./BigImage.css";
import Spinner from "../elements/Spinner";

const BigImage = (props) => {
	const [imageLoaded, setImageLoaded] = useState(false);

	let imageDir;
	let image;

	if (props.customImage && props.customImage.length > 0) {
		imageDir = "user";
		image = props.customImage;
	} else {
		imageDir = "default";
		image = props.image;
	}

	const handleImgLoad = () => {
		setImageLoaded(true);
	};

	return (
		<Overlay onClose={props.onClose} className="big-image">
			<div className="mobile-close" onClick={props.onClose}>
				<i className="fa fa-solid fa-close"></i>
			</div>
			{!imageLoaded && <Spinner className="absolute"></Spinner>}
			<img
				onLoad={handleImgLoad}
				src={process.env.REACT_APP_IMAGES_DOMAIN + "/images/" + imageDir + "/big/" + image}
				alt={image}
			/>
		</Overlay>
	);
};

export default BigImage;
