import React from "react";

const DebutCardbackFilter = (props) => {
	const handleShowCardbackOptions = () => {
		props.handleShowHideOptions("cardback");
	};

	return (
		<div className="filter cardback-filter">
			<div className="filter-heading">Debut cardback</div>
			<div className="filter-options show-options">
				<div
					className={`options-show-hide ${props.showCardbackOptions ? "active" : ""}`}
					onClick={handleShowCardbackOptions}
				>
					<i className={"fa-solid " + (props.showCardbackOptions ? "fa-chevron-up" : "fa-chevron-down")}></i>
				</div>
			</div>
			{props.showCardbackOptions && (
				<div className="filter-options-cont">
					<div className="filter-options-section">
						<div className="options-section-title">SW:</div>
						<div
							id="debut-cb-sw-12"
							className={`option-item${props.cardbacks.includes("debut-cb-sw-12") ? " active" : ""}`}
							onClick={props.onChange}
						>
							12
						</div>
						<div
							id="debut-cb-sw-20"
							className={`option-item${props.cardbacks.includes("debut-cb-sw-20") ? " active" : ""}`}
							onClick={props.onChange}
						>
							20
						</div>
						<div
							id="debut-cb-sw-21"
							className={`option-item${props.cardbacks.includes("debut-cb-sw-21") ? " active" : ""}`}
							onClick={props.onChange}
						>
							21
						</div>
					</div>
					<div className="filter-options-section">
						<div className="options-section-title">ESB:</div>
						<div
							id="debut-cb-esb-31"
							className={`option-item${props.cardbacks.includes("debut-cb-esb-31") ? " active" : ""}`}
							onClick={props.onChange}
						>
							31
						</div>
						<div
							id="debut-cb-esb-32"
							className={`option-item${props.cardbacks.includes("debut-cb-esb-32") ? " active" : ""}`}
							onClick={props.onChange}
						>
							32
						</div>
						<div
							id="debut-cb-esb-41"
							className={`option-item${props.cardbacks.includes("debut-cb-esb-41") ? " active" : ""}`}
							onClick={props.onChange}
						>
							41
						</div>
						<div
							id="debut-cb-esb-45"
							className={`option-item${props.cardbacks.includes("debut-cb-esb-45") ? " active" : ""}`}
							onClick={props.onChange}
						>
							45
						</div>
						<div
							id="debut-cb-esb-47"
							className={`option-item${props.cardbacks.includes("debut-cb-esb-47") ? " active" : ""}`}
							onClick={props.onChange}
						>
							47
						</div>
						<div
							id="debut-cb-esb-48"
							className={`option-item${props.cardbacks.includes("debut-cb-esb-48") ? " active" : ""}`}
							onClick={props.onChange}
						>
							48
						</div>
					</div>
					<div className="filter-options-section">
						<div className="options-section-title">RotJ:</div>
						<div
							id="debut-cb-rotj-65"
							className={`option-item${props.cardbacks.includes("debut-cb-rotj-65") ? " active" : ""}`}
							onClick={props.onChange}
						>
							65
						</div>
						<div
							id="debut-cb-rotj-77"
							className={`option-item${props.cardbacks.includes("debut-cb-rotj-77") ? " active" : ""}`}
							onClick={props.onChange}
						>
							77
						</div>
						<div
							id="debut-cb-rotj-79"
							className={`option-item${props.cardbacks.includes("debut-cb-rotj-79") ? " active" : ""}`}
							onClick={props.onChange}
						>
							79
						</div>
					</div>
					<div className="filter-options-section">
						<div className="options-section-title">PotF:</div>
						<div
							id="debut-cb-potf-92"
							className={`option-item${props.cardbacks.includes("debut-cb-potf-92") ? " active" : ""}`}
							onClick={props.onChange}
						>
							92
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default DebutCardbackFilter;
