import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import Wrapper from "./components/structures/Wrapper";
import MaintenanceMode from "./components/elements/MaintenanceMode";

const App = (props) => {
	// Maintenance mode
	const maintenanceMode = false;

	const localStorageMode = localStorage.getItem("mode");
	const localStorageScope = localStorage.getItem("scope");

	const [data, setData] = useState([]);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [mode, setMode] = useState(localStorageMode ? localStorageMode : "loose");

	// User states
	const [username, setUsername] = useState("starterdata");
	const [token, setToken] = useState(null);
	const [sharedLink, setSharedLink] = useState(props.sharedLink ? props.sharedLink : null);
	const [compactMode, setCompactMode] = useState(false);
	const [userIsSharing, setUserIsSharing] = useState(false);

	const clearSharedLink = () => {
		setSharedLink(false);
	};

	const handleToken = (token) => {
		setToken(token);
	};

	const paramUser = useParams().sharedUser;
	useEffect(() => {
		if (props.sharedLink) {
			getSharedData(mode);
		} else {
			getData(mode);
		}
	}, []);

	const getSharedData = (mode) => {
		axios
			.get(process.env.REACT_APP_SERVER_DOMAIN + "/api/users/shared/" + paramUser)
			.then((res) => {
				// get the correct data based on the mode passed in
				let initialData;
				switch (mode) {
					case "loose":
						initialData = res.data.loose;
						break;
					case "loose-graded":
						initialData = res.data.looseGraded;
						break;
					case "moc":
						initialData = res.data.moc;
						break;
					case "moc-graded":
						initialData = res.data.mocGraded;
						break;
					default:
				}
				setData(initialData);
				setDataLoaded(true);
				const storedData = JSON.parse(localStorage.getItem("userData"));
				if (storedData && storedData.token) {
					setUsername(storedData.username);
					setToken(storedData.token);
				}
			})
			.catch(() => {
				setSharedLink(false);
				getData("loose");
			});
	};

	const getData = (mode) => {
		// setSharedLink(false);
		const storedData = JSON.parse(localStorage.getItem("userData"));
		// if logged in
		if (storedData && storedData.token) {
			// set up headers config
			const config = {
				headers: {
					Authorization: "Bearer " + storedData.token
				}
			};
			setUsername(storedData.username);
			setToken(storedData.token);
			axios.get(process.env.REACT_APP_SERVER_DOMAIN + "/api/users/" + storedData.username, config).then((res) => {
				// get the correct data based on the mode passed in
				let initialData;
				switch (mode) {
					case "loose":
						initialData = res.data.loose;
						break;
					case "loose-graded":
						initialData = res.data.looseGraded;
						break;
					case "moc":
						initialData = res.data.moc;
						break;
					case "moc-graded":
						initialData = res.data.mocGraded;
						break;
					default:
				}
				setData(initialData);
				setDataLoaded(true);
				setCompactMode(res.data.compactMode);
				setUserIsSharing(res.data.collectionShare);
			});
			// if not logged in
		} else {
			axios.get(process.env.REACT_APP_SERVER_DOMAIN + "/api/users/starterdata").then((res) => {
				// get the correct data based on the mode passed in
				let initialData;
				switch (mode) {
					case "loose":
						initialData = res.data.loose;
						break;
					case "loose-graded":
						initialData = res.data.looseGraded;
						break;
					case "moc":
						initialData = res.data.moc;
						break;
					case "moc-graded":
						initialData = res.data.mocGraded;
						break;
					default:
				}
				setData(initialData);
				setDataLoaded(true);
			});
		}
	};

	const handleTabClick = (tabMode, login) => {
		setDataLoaded(false);
		setData([]);
		setMode(tabMode);
		if (props.sharedLink && !login) {
			getSharedData(tabMode);
		} else {
			getData(tabMode);
		}
	};

	if (maintenanceMode) {
		return <MaintenanceMode></MaintenanceMode>
	}

	return (
		<Wrapper
			data={data}
			username={username}
			setUsername={setUsername}
			token={token}
			handleToken={handleToken}
			clearSharedLink={clearSharedLink}
			sharedLink={sharedLink}
			paramUser={paramUser}
			mode={mode}
			onTabClick={handleTabClick}
			dataLoaded={dataLoaded}
			setMode={setMode}
			localStorageScope={localStorageScope}
			compactMode={compactMode}
			userIsSharing={userIsSharing}
			passwordReset={props.passwordReset}
			getData={getData}
		></Wrapper>
	);
};

export default App;
