import React, { useState } from "react";

import Overlay from "../../elements/Overlay";
import Input from "../../elements/Input";

const AddForm = (props) => {
	const [cardData, setCardData] = useState({
		name: "",
		image: "",
		owned: false,
		replace: false,
		variant: "",
		coo: "",
		accessories: [],
		scope: "base",
		yearReleased: "",
		yearPurchased: "",
		yearReplaced: "",
		debutCardback: "",
		variants: []
	});
	const [showAddAcc, setShowAddAcc] = useState(false);
	const [addAccText, setAddAccText] = useState("");
	const [isValidName, setIsValidName] = useState(false);

	const handleStringChange = (e) => {
		const el = e.target.id;
		const val = e.target.value;
		switch (el) {
			case "name":
				setCardData((prev) => ({ ...prev, name: val }));
				break;
			case "scope":
				setCardData((prev) => ({ ...prev, scope: val }));
				break;
			case "image":
				setCardData((prev) => ({ ...prev, image: val }));
				break;
			case "variant":
				setCardData((prev) => ({ ...prev, variant: val }));
				break;
			case "coo":
				setCardData((prev) => ({ ...prev, coo: val }));
				break;
			case "year-purchased":
				setCardData((prev) => ({ ...prev, yearPurchased: val }));
				break;
			case "price-paid":
				setCardData((prev) => ({ ...prev, pricePaid: val }));
				break;
			case "purchased-from":
				setCardData((prev) => ({ ...prev, whereBought: val }));
				break;
			case "condition":
				setCardData((prev) => ({ ...prev, condition: val }));
				break;
			case "notes":
				setCardData((prev) => ({ ...prev, notes: val }));
				break;
			case "year-released":
				setCardData((prev) => ({ ...prev, yearReleased: val }));
				break;
			case "release-order":
				setCardData((prev) => ({ ...prev, releaseOrder: val }));
				break;
			case "debut-cardback":
				setCardData((prev) => ({ ...prev, debutCardback: val }));
				break;
			case "year-replaced":
				setCardData((prev) => ({ ...prev, yearReplace: val }));
				break;
			default:
		}
	};

	const handleBoolChange = (e) => {
		const el = e.target.id;
		switch (el) {
			case "owned-yes":
				setCardData((prev) => ({ ...prev, owned: true }));
				break;
			case "owned-no":
				setCardData((prev) => ({ ...prev, owned: false }));
				break;
			case "replace-yes":
				setCardData((prev) => ({ ...prev, replace: true }));
				break;
			case "replace-no":
				setCardData((prev) => ({ ...prev, replace: false }));
				break;
			case "wantlist-yes":
				setCardData((prev) => ({ ...prev, wantList: true }));
				break;
			case "wantlist-no":
				setCardData((prev) => ({ ...prev, wantList: false }));
				break;
			default:
		}
	};

	const handleSaveClick = (e) => {
		e.preventDefault();
		props.onSave(cardData, isValidName);
	};

	const handleAddAccClick = (e) => {
		e.preventDefault();
		setShowAddAcc((prev) => !prev);
	};

	const handleAddAccTextChange = (e) => setAddAccText(e.target.value);

	const handleAddAccessory = (e) => {
		e.preventDefault();
		const val = addAccText;
		setCardData((prev) => ({ ...prev, accessories: [...cardData.accessories, { name: val, owned: false }] }));
		setShowAddAcc((prev) => !prev);
	};

	const handleDeleteAccessory = (e) => {
		const i = e.target.id;
		setCardData((prev) => ({
			...prev,
			accessories: prev.accessories.filter((item, index) => index.toString() !== i)
		}));
	};

	const handleCheckAccessory = (e) => {
		const i = e.target.value;
		// Set the card data with an updated accessories array
		setCardData((prev) => {
			return {
				...prev,
				accessories: prev.accessories.map((item, index) => {
					return {
						...item,
						owned: index.toString() === i ? !prev.accessories[i].owned : item.owned
					};
				})
			};
		});
	};

	const handleAccNameChange = (e) => {
		const i = e.target.id;
		const name = e.target.value;
		// Set the card data with an updated accessories array
		const newAcc = cardData.accessories.map((item, index) => {
			return {
				...item,
				name: index.toString() === i ? name : item.name
			};
		});

		setCardData((prev) => ({ ...prev, accessories: newAcc }));
	};

	const handleNameValid = (valid) => {
		setIsValidName(valid);
	};

	return (
		<Overlay onClose={props.onCancel} className="add-form">
			<h2>Add a new item</h2>
			<form action="" onSubmit={handleSaveClick}>
				<div className="form-column form-column-one">
					<Input
						element="input"
						type="text"
						label="Name"
						id="name"
						value={cardData.name}
						onChange={handleStringChange}
						errorText=" cannot be blank"
						validator="required"
						isValid={isValidName}
						setFormValid={handleNameValid}
					/>
					<Input
						element="input"
						type="text"
						label="Image file"
						id="image"
						value={cardData.image}
						onChange={handleStringChange}
					/>
					<Input
						element="select"
						label="Collection"
						id="scope"
						value={cardData.scope}
						onChange={handleStringChange}
						options={[
							{ value: "base", label: "Figures" },
							{ value: "creatures", label: "Creatures" }
						]}
					/>
					<Input
						className="form-radio"
						element="input"
						type="radio"
						label="Owned"
						id="owned-radio"
						onChange={handleBoolChange}
						radios={[
							{ id: "owned-yes", checked: cardData.owned, label: "Yes" },
							{ id: "owned-no", checked: !cardData.owned, label: "No" }
						]}
					/>
					<Input
						className="form-radio"
						element="input"
						type="radio"
						label="Exclude from collection count"
						id="replace-radio"
						onChange={handleBoolChange}
						radios={[
							{ id: "replace-yes", checked: cardData.replace, label: "Yes" },
							{ id: "replace-no", checked: !cardData.replace, label: "No" }
						]}
					/>
					<Input
						element="input"
						type="text"
						label="Variant info"
						id="variant"
						value={cardData.variant}
						onChange={handleStringChange}
					/>
					<Input element="input" type="text" label="COO" id="coo" value={cardData.coo} onChange={handleStringChange} />
					<div className="form-control accessories-edit">
						<label htmlFor="accessories">Accessories:</label>
						{cardData.accessories.map((item, index) => {
							return (
								<div key={index}>
									<input
										type="checkbox"
										value={index}
										checked={cardData.accessories[index].owned}
										onChange={handleCheckAccessory}
									/>{" "}
									<input
										type="text"
										id={index}
										defaultValue={cardData.accessories[index].name}
										onChange={handleAccNameChange}
									/>
									&nbsp;
									<span className="delete-acc">
										<i className="fa-solid fa-close" id={index} onClick={handleDeleteAccessory}></i>
									</span>
								</div>
							);
						})}
						{!showAddAcc ? (
							<span className="form-link" onClick={handleAddAccClick}>
								Add an accessory
							</span>
						) : (
							<div className="add-accessory-div">
								<input type="text" id="add-accessory-field" onChange={handleAddAccTextChange} />
								<button onClick={handleAddAccessory}>Add</button>
								<span className="accessory-cancel" onClick={handleAddAccClick}>
									Cancel
								</span>
							</div>
						)}
					</div>
					<Input
						element="input"
						type="text"
						label="Year released"
						id="year-released"
						value={cardData.yearReleased}
						onChange={handleStringChange}
					/>
				</div>
				<div className="form-column form-column-two">
					<Input
						element="input"
						type="text"
						label="Sort order"
						id="release-order"
						value={cardData.releaseOrder}
						onChange={handleStringChange}
					/>
					<Input
						element="select"
						label="Debut cardback"
						id="debut-cardback"
						value={cardData.debutCardback}
						onChange={handleStringChange}
						options={[
							{ value: "", label: "-- select an option --" },
							{ value: "sw-12", label: "Star Wars 12-Back" },
							{ value: "sw-20", label: "Star Wars 20-Back" },
							{ value: "sw-21", label: "Star Wars 21-Back" },
							{ value: "esb-31", label: "The Empire Strikes Back 31-Back" },
							{ value: "esb-32", label: "The Empire Strikes Back 32-Back" },
							{ value: "esb-41", label: "The Empire Strikes Back 41-Back" },
							{ value: "esb-45", label: "The Empire Strikes Back 45-Back" },
							{ value: "esb-47", label: "The Empire Strikes Back 47-Back" },
							{ value: "esb-48", label: "The Empire Strikes Back 48-Back" },
							{ value: "rotj-65", label: "Return of the Jedi 65-Back" },
							{ value: "rotj-77", label: "Return of the Jedi 77-Back" },
							{ value: "rotj-79", label: "Return of the Jedi 79-Back" },
							{ value: "potf-92", label: "Power of the Force 92-Back" }
						]}
					/>
					<Input
						element="input"
						type="text"
						label="Year purchased"
						id="year-purchased"
						value={cardData.yearPurchased}
						onChange={handleStringChange}
					/>
					<Input
						element="input"
						type="text"
						label="Price paid"
						id="price-paid"
						value={cardData.pricePaid}
						onChange={handleStringChange}
					/>
					<Input
						element="input"
						type="text"
						label="Purchased from"
						id="purchased-from"
						value={cardData.whereBought}
						onChange={handleStringChange}
					/>
					<Input
						element="select"
						label="Condition"
						id="condition"
						value={cardData.condition}
						onChange={handleStringChange}
						options={[
							{ value: "10", label: "10" },
							{ value: "9", label: "9" },
							{ value: "8", label: "8" },
							{ value: "7", label: "7" },
							{ value: "6", label: "6" },
							{ value: "5", label: "5" },
							{ value: "4", label: "4" },
							{ value: "3", label: "3" },
							{ value: "2", label: "2" },
							{ value: "1", label: "1" }
						]}
					/>
					<Input
						className="form-radio"
						element="input"
						type="radio"
						label="Include in want list"
						id="wantlist-radio"
						onChange={handleBoolChange}
						radios={[
							{ id: "wantlist-yes", checked: cardData.wantList, label: "Yes" },
							{ id: "wantlist-no", checked: !cardData.wantList, label: "No" }
						]}
					/>
					<Input
						element="textarea"
						label="Notes: "
						value={cardData.notes}
						rows="5"
						id="notes"
						maxLength="300"
						onChange={handleStringChange}
					/>
					<div className="edit-save-cancel">
						<div>
							<input type="submit" value="Save" disabled={isValidName ? false : true} />
							<button className="cancel" onClick={props.onCancel}>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</form>
		</Overlay>
	);
};

export default AddForm;
