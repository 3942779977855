import React from "react";

import Filter from "../../elements/Filter";

const WantListFilter = (props) => {
	return (
		<Filter
			className="wantlist-filter"
			heading="In want list"
			state={props.wantList}
			onChange={props.onChange}
			options={[
				{ id: "want-list-yes", label: "Yes" },
				{ id: "want-list-no", label: "No" },
				{ id: "want-list-both", label: "Both" }
			]}
		></Filter>
	);
};

export default WantListFilter;
