import React, { useState, useReducer, useCallback } from "react";

import Overlay from "../../elements/Overlay";
import Input from "../../elements/Input";
import formReducer from "../../../utils/formReducer";
import ImageUpload from "../../elements/ImageUpload";

const AddDuplicateForm = (props) => {
	const [formState, dispatch] = useReducer(formReducer, {
		inputs: {
			name: { value: "", isValid: true }
		},
		isValid: true
	});

	const [cardData, setCardData] = useState({
		name: props.parentData.name,
		image: props.parentData.image,
		customImage: "",
		owned: true,
		replace: false,
		variant: "",
		coo: "",
		licensee: "kenner",
		factory: "",
		accessories: props.parentData.accessories,
		scope: props.parentData.scope + "-duplicates",
		releaseOrder: "",
		yearReleased: props.parentData.yearReleased,
		yearPurchased: "",
		pricePaid: "",
		whereBought: "",
		yearReplaced: "",
		debutCardback: props.parentData.debutCardback
	});

	const [showAddAcc, setShowAddAcc] = useState(false);
	const [addAccText, setAddAccText] = useState("");
	const [isValidName, setIsValidName] = useState(true);

	const handleStringChange = (e) => {
		const el = e.target.id;
		const val = e.target.value;
		switch (el) {
			case "name":
				setCardData((prev) => ({ ...prev, name: val }));
				break;
			case "scope":
				setCardData((prev) => ({ ...prev, scope: val }));
				break;
			case "image":
				setCardData((prev) => ({ ...prev, image: val }));
				break;
			case "variant":
				setCardData((prev) => ({ ...prev, variant: val }));
				break;
			case "coo":
				setCardData((prev) => ({ ...prev, coo: val }));
				break;
			case "licensee":
				setCardData((prev) => ({ ...prev, licensee: val }));
				break;
			case "factory":
				setCardData((prev) => ({ ...prev, factory: val }));
				break;
			case "year-purchased":
				setCardData((prev) => ({ ...prev, yearPurchased: val }));
				break;
			case "price-paid":
				setCardData((prev) => ({ ...prev, pricePaid: val }));
				break;
			case "purchased-from":
				setCardData((prev) => ({ ...prev, whereBought: val }));
				break;
			case "condition":
				setCardData((prev) => ({ ...prev, condition: val }));
				break;
			case "notes":
				setCardData((prev) => ({ ...prev, notes: val }));
				break;
			case "year-released":
				setCardData((prev) => ({ ...prev, yearReleased: val }));
				break;
			case "release-order":
				setCardData((prev) => ({ ...prev, releaseOrder: val }));
				break;
			case "debut-cardback":
				setCardData((prev) => ({ ...prev, debutCardback: val }));
				break;
			case "year-replaced":
				setCardData((prev) => ({ ...prev, yearReplace: val }));
				break;
			case "grade":
				setCardData((prev) => ({ ...prev, grade: val }));
				break;
			case "grade-qualifier":
				setCardData((prev) => ({ ...prev, gradeQualifier: val }));
				break;
			case "sub-grades":
				setCardData((prev) => ({ ...prev, subGrades: val }));
				break;
			case "company":
				setCardData((prev) => ({ ...prev, company: val }));
				break;
			case "date-graded":
				setCardData((prev) => ({ ...prev, dateGraded: val }));
				break;
			case "cardback-owned":
				setCardData((prev) => ({ ...prev, cardbackOwned: val }));
				break;
			default:
		}
	};

	const handleSaveClick = (e) => {
		e.preventDefault();
		let imageValue;
		if (formState.inputs.image) {
			imageValue = formState.inputs.image.value;
		}
		props.onSaveDuplicate(cardData, imageValue ? formState.inputs.image.value : null, formState.isValid);
	};

	const handleAddAccClick = (e) => {
		e.preventDefault();
		setShowAddAcc((prev) => !prev);
	};

	const handleAddAccTextChange = (e) => setAddAccText(e.target.value);

	const handleAddAccessory = (e) => {
		e.preventDefault();
		const val = addAccText;
		setCardData((prev) => ({ ...prev, accessories: [...prev.accessories, { name: val, owned: false }] }));
		setShowAddAcc((prev) => !prev);
	};

	const handleDeleteAccessory = (e) => {
		const i = e.target.id;
		setCardData((prev) => ({
			...prev,
			accessories: prev.accessories.filter((item, index) => index.toString() !== i)
		}));
	};

	const handleCheckAccessory = (e) => {
		const i = e.target.value;
		// Set the card data with an updated accessories array
		setCardData((prev) => {
			return {
				...cardData,
				accessories: cardData.accessories.map((item, index) => {
					return {
						...item,
						owned: index.toString() === i ? !prev.accessories[i].owned : item.owned
					};
				})
			};
		});
	};

	const handleAccNameChange = (e) => {
		const i = e.target.id;
		const name = e.target.value;
		// Set the card data with an updated accessories array
		const newAcc = cardData.accessories.map((item, index) => {
			return {
				...item,
				name: index.toString() === i ? name : item.name
			};
		});

		setCardData((prev) => ({ ...prev, accessories: newAcc }));
	};

	const handleNameValid = (valid) => {
		setIsValidName(valid);
	};

	const inputHandler = useCallback((id, value, isValid) => {
		dispatch({
			type: "INPUT_CHANGE",
			value: value,
			isValid: isValid,
			inputId: id
		});
		switch (id) {
			case "name":
				setCardData((prev) => ({ ...prev, name: value }));
				break;
			default:
		}
	}, []);

	return (
		<Overlay onClose={props.onCancel} className="add-form">
			<h2>Add a new {props.parentData.name} duplicate</h2>
			<form action="" onSubmit={handleSaveClick}>
				<div className="form-column form-column-one">
					<Input
						element="input"
						type="text"
						label="Name"
						id="name"
						value={cardData.name}
						onChange={handleStringChange}
						errorText=" required"
						validator={["required"]}
						isValid={isValidName}
						setFormValid={handleNameValid}
					/>
					<ImageUpload id="image" onInput={inputHandler} add={true}></ImageUpload>
					<Input
						element="input"
						type="text"
						label="Variant info"
						id="variant"
						value={cardData.variant}
						onChange={handleStringChange}
					/>
					<Input element="input" type="text" label="COO" id="coo" value={cardData.coo} onChange={handleStringChange} />
					<Input
						element="select"
						label="Licensee"
						id="licensee"
						value={cardData.licensee}
						onChange={handleStringChange}
						options={[
							{ value: "kenner", label: "Kenner" },
							{ value: "clipper", label: "Clipper" },
							{ value: "glasslite", label: "Glasslite" },
							{ value: "lili-ledy", label: "Lili Ledy" },
							{ value: "meccano", label: "Meccano" },
							{ value: "model-trem", label: "Model Trem" },
							{ value: "pbp", label: "PBP" },
							{ value: "palitoy", label: "Palitoy" },
							{ value: "poch", label: "Poch" },
							{ value: "takara", label: "Takara" },
							{ value: "toltoys", label: "Toltoys" },
							{ value: "top-toys", label: "TopToys" },
							{ value: "uzay", label: "Uzay" }
						]}
					/>
					<Input
						element="select"
						label="Factory"
						id="factory"
						value={cardData.factory}
						onChange={handleStringChange}
						options={[
							{ value: "", label: "-- select an option --" },
							{ value: "kader", label: "Kader" },
							{ value: "unitoy", label: "Unitoy" },
							{ value: "smile", label: "Smile" },
							{ value: "universal", label: "Universal" },
							{ value: "jetta", label: "Jetta" },
							{ value: "qualidux", label: "Qualidux" },
							{ value: "great-shing", label: "Great Shing" },
							{ value: "taiwan", label: "Taiwan" }
						]}
					/>
					<div className="form-control accessories-edit">
						<label htmlFor="accessories">Accessories:</label>
						{cardData.accessories.map((item, index) => {
							return (
								<div key={index}>
									<input
										type="checkbox"
										value={index}
										checked={cardData.accessories[index].owned}
										onChange={handleCheckAccessory}
									/>{" "}
									<input
										type="text"
										id={index}
										defaultValue={cardData.accessories[index].name}
										onChange={handleAccNameChange}
									/>
									&nbsp;
									<span className="delete-acc">
										<i className="fa-solid fa-close" id={index} onClick={handleDeleteAccessory}></i>
									</span>
								</div>
							);
						})}
						{!showAddAcc ? (
							<span className="form-link" onClick={handleAddAccClick}>
								Add an accessory
							</span>
						) : (
							<div className="add-accessory-div">
								<input type="text" id="add-accessory-field" onChange={handleAddAccTextChange} />
								<button onClick={handleAddAccessory}>Add</button>
								<span className="accessory-cancel" onClick={handleAddAccClick}>
									Cancel
								</span>
							</div>
						)}
					</div>
					<Input
						element="input"
						type="text"
						label="Year released"
						id="year-released"
						value={cardData.yearReleased}
						onChange={handleStringChange}
					/>
				</div>
				<div className="form-column form-column-two">
					<Input
						element="input"
						type="text"
						label="Sort order"
						id="release-order"
						value={cardData.releaseOrder}
						onChange={handleStringChange}
					/>
					{props.parentData.scope === "base" && (
						<Input
							element="select"
							label="Debut cardback"
							id="debut-cardback"
							value={cardData.debutCardback}
							onChange={handleStringChange}
							options={[
								{ value: "", label: "-- select an option --" },
								{ value: "sw-12", label: "Star Wars 12-Back" },
								{ value: "sw-20", label: "Star Wars 20-Back" },
								{ value: "sw-21", label: "Star Wars 21-Back" },
								{ value: "esb-31", label: "The Empire Strikes Back 31-Back" },
								{ value: "esb-32", label: "The Empire Strikes Back 32-Back" },
								{ value: "esb-41", label: "The Empire Strikes Back 41-Back" },
								{ value: "esb-45", label: "The Empire Strikes Back 45-Back" },
								{ value: "esb-47", label: "The Empire Strikes Back 47-Back" },
								{ value: "esb-48", label: "The Empire Strikes Back 48-Back" },
								{ value: "rotj-65", label: "Return of the Jedi 65-Back" },
								{ value: "rotj-77", label: "Return of the Jedi 77-Back" },
								{ value: "rotj-79", label: "Return of the Jedi 79-Back" },
								{ value: "potf-92", label: "Power of the Force 92-Back" }
							]}
						/>
					)}
					{(props.mode === "moc" || props.mode === "moc-graded") && props.parentData.scope === "base" && (
						<Input
							element="select"
							label="Cardback owned"
							id="cardback-owned"
							value={cardData.cardbackOwned}
							onChange={handleStringChange}
							options={[
								{ value: "", label: "-- select an option --" },
								{ value: "sw-12", label: "Star Wars 12-Back" },
								{ value: "sw-20", label: "Star Wars 20-Back" },
								{ value: "sw-21", label: "Star Wars 21-Back" },
								{ value: "esb-31", label: "The Empire Strikes Back 31-Back" },
								{ value: "esb-32", label: "The Empire Strikes Back 32-Back" },
								{ value: "esb-41", label: "The Empire Strikes Back 41-Back" },
								{ value: "esb-45", label: "The Empire Strikes Back 45-Back" },
								{ value: "esb-47", label: "The Empire Strikes Back 47-Back" },
								{ value: "esb-48", label: "The Empire Strikes Back 48-Back" },
								{ value: "rotj-65", label: "Return of the Jedi 65-Back" },
								{ value: "rotj-77", label: "Return of the Jedi 77-Back" },
								{ value: "rotj-79", label: "Return of the Jedi 79-Back" },
								{ value: "potf-92", label: "Power of the Force 92-Back" }
							]}
						/>
					)}
					<Input
						element="input"
						type="text"
						label="Date purchased"
						id="year-purchased"
						value={cardData.yearPurchased}
						onChange={handleStringChange}
					/>
					<Input
						element="input"
						type="text"
						label="Price paid"
						id="price-paid"
						value={cardData.pricePaid}
						onChange={handleStringChange}
					/>
					<Input
						element="input"
						type="text"
						label="Purchased from"
						id="purchased-from"
						value={cardData.whereBought}
						onChange={handleStringChange}
					/>
					{(props.mode === "loose" || props.mode === "moc") && (
						<Input
							element="select"
							label="Condition"
							id="condition"
							value={cardData.condition}
							onChange={handleStringChange}
							options={[
								{ value: "", label: "-- select an option --" },
								{ value: "10", label: "10" },
								{ value: "9", label: "9" },
								{ value: "8", label: "8" },
								{ value: "7", label: "7" },
								{ value: "6", label: "6" },
								{ value: "5", label: "5" },
								{ value: "4", label: "4" },
								{ value: "3", label: "3" },
								{ value: "2", label: "2" },
								{ value: "1", label: "1" }
							]}
						/>
					)}
					{(props.mode === "loose-graded" || props.mode === "moc-graded") && (
						<>
							<Input
								element="select"
								label="Grading company"
								id="company"
								value={cardData.company}
								onChange={handleStringChange}
								options={[
									{ value: "", label: "-- select an option --" },
									{ value: "AFA", label: "AFA" },
									{ value: "CAS", label: "CAS" },
									{ value: "UKG", label: "UKG" }
								]}
							/>
							<Input
								element="select"
								label="Grade"
								id="grade"
								value={cardData.grade}
								onChange={handleStringChange}
								options={[
									{ value: "", label: "-- select an option --" },
									{ value: "10", label: "10" },
									{ value: "20", label: "20" },
									{ value: "30", label: "30" },
									{ value: "40", label: "40" },
									{ value: "50", label: "50" },
									{ value: "60", label: "60" },
									{ value: "70", label: "70" },
									{ value: "75", label: "75" },
									{ value: "75+", label: "75+" },
									{ value: "80", label: "80" },
									{ value: "80+", label: "80+" },
									{ value: "85", label: "85" },
									{ value: "85+", label: "85+" },
									{ value: "90", label: "90" },
									{ value: "90+", label: "90+" },
									{ value: "95", label: "95" },
									{ value: "95+", label: "95+" },
									{ value: "100", label: "100" }
								]}
							/>
							<Input
								element="select"
								label="Grade qualifier"
								id="grade-qualifier"
								value={cardData.gradeQualifier}
								onChange={handleStringChange}
								options={[
									{ value: "", label: "-- select an option --" },
									{ value: "Yellowing", label: "Yellowing" },
									{ value: "Qualified", label: "Qualified" },
									{ value: "Uncirculated", label: "Uncirculated" }
								]}
							/>
							{props.mode === "moc-graded" && (
								<Input
									element="input"
									type="text"
									label="Sub grades"
									id="sub-grades"
									value={cardData.subGrades}
									onChange={handleStringChange}
								/>
							)}
							<Input
								element="input"
								type="text"
								label="Date graded"
								id="date-graded"
								value={cardData.dateGraded}
								onChange={handleStringChange}
							/>
						</>
					)}
					<Input
						element="textarea"
						label="Notes: "
						value={cardData.notes}
						rows="5"
						id="notes"
						maxLength="300"
						onChange={handleStringChange}
					/>
					<div className="mobile-close" onClick={props.onCancel}>
						<i className="fa fa-solid fa-close"></i>
					</div>
					<div className="edit-save-cancel">
						<div>
							<input type="submit" value="Save" disabled={isValidName ? false : true} />
							<button className="cancel" onClick={props.onCancel}>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</form>
		</Overlay>
	);
};

export default AddDuplicateForm;
