import React from "react";
import "./CollectionSelect.css";

const CollectionSelect = (props) => {
	return (
		<div className="collection-select-cont">
			<div className="collection-select">
				<div className={props.scope === "base" ? "active" : ""} id="base-collection" onClick={props.onChange}>
					Figures &nbsp;<span>{props.baseOfficial.length}</span> / {props.figures.length}
				</div>
				<div
					className={props.scope === "creatures" ? "active" : ""}
					id="creatures-collection"
					onClick={props.onChange}
				>
					Creatures &nbsp;<span>{props.creaturesOfficial.length}</span> / {props.creatures.length}
				</div>
				<div className={props.scope === "vehicles" ? "active" : ""} id="vehicles-collection" onClick={props.onChange}>
					Vehicles &nbsp;<span>{props.vehiclesOfficial.length}</span> / {props.vehicles.length}
				</div>
				<div className={props.scope === "playsets" ? "active" : ""} id="playsets-collection" onClick={props.onChange}>
					Playsets &nbsp;<span>{props.playsetsOfficial.length}</span> / {props.playsets.length}
				</div>
				<div className={props.scope === "minirigs" ? "active" : ""} id="minirigs-collection" onClick={props.onChange}>
					Mini-Rigs &nbsp;<span>{props.minirigsOfficial.length}</span> / {props.minirigs.length}
				</div>
			</div>
		</div>
	);
};

export default CollectionSelect;
