import React from "react";

import Filter from "../../elements/Filter";

const CompleteFilter = (props) => {
	return (
		<Filter
			className="complete-filter"
			heading="Complete"
			state={props.complete}
			onChange={props.onChange}
			options={[
				{ id: "complete", label: "Yes" },
				{ id: "incomplete", label: "No" },
				{ id: "both-complete", label: "Both" }
			]}
		></Filter>
	);
};

export default CompleteFilter;
