import React from "react";
import Tab from "../elements/Tab";

const TabsContainer = (props) => {
	return (
		<div className="tabs-cont">
			<Tab id="loose" mode={props.mode} onClick={props.onTabClick}>Loose</Tab>
			<Tab id="loose-graded" mode={props.mode} onClick={props.onTabClick}>Loose Graded</Tab>
			<Tab id="moc" mode={props.mode} onClick={props.onTabClick}>MOC/MISB</Tab>
			<Tab id="moc-graded" mode={props.mode} onClick={props.onTabClick}>MOC/MISB Graded</Tab>
		</div>
	);
};

export default TabsContainer;
