import React from "react";

const CompanyFilter = (props) => {
	const handleShowCompanyOptions = () => {
		props.handleShowHideOptions("company");
	};

	return (
		<div className="filter company-filter">
			<div className="filter-heading">Company</div>
			<div className="filter-options show-options">
				<div
					className={`options-show-hide ${props.showCompanyOptions ? "active" : ""}`}
					onClick={handleShowCompanyOptions}
				>
					<i className={"fa-solid " + (props.showCompanyOptions ? "fa-chevron-up" : "fa-chevron-down")}></i>
				</div>
			</div>
			{props.showCompanyOptions && (
				<div className="filter-options-cont">
					<div className="filter-options-section">
						<div className="options-section-title">Company:</div>
						<div
							id="company-afa"
							className={`option-item${props.company.includes("company-afa") ? " active" : ""}`}
							onClick={props.onChange}
						>
							AFA
						</div>
						<div
							id="company-cas"
							className={`option-item${props.company.includes("company-cas") ? " active" : ""}`}
							onClick={props.onChange}
						>
							CAS
						</div>
						<div
							id="company-ukg"
							className={`option-item${props.company.includes("company-ukg") ? " active" : ""}`}
							onClick={props.onChange}
						>
							UKG
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default CompanyFilter;
