import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "./App";
import "./index.css";

const router = createBrowserRouter([
	{ path: "/:username?", element: <App /> },
	{ path: "/shared/:sharedUser", element: <App sharedLink={true} /> },
	{ path: "/passwordreset/", element: <App passwordReset={true} /> }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
