import React, { useState, useCallback, useReducer } from "react";

import "./Profile.css";
import Overlay from "../../elements/Overlay";
import Input from "../../elements/Input";
import { VALIDATOR_MINLENGTH } from "../../../utils/validators";
import formReducer from "../../../utils/formReducer";

const Profile = (props) => {
	const [, dispatch] = useReducer(formReducer, {
		inputs: {
			password: { value: "", isValid: false }
		},
		isValid: false
	});

	const [profileSettings, setProfileSettings] = useState(props.profileSettings);
	const [urlIsVisible, setUrlIsVisible] = useState(props.profileSettings.userIsSharing);
	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const [showPasswordError, setShowPasswordError] = useState(false);

	const handleSettingsChange = (e) => {
		switch (e.target.id) {
			case "compact-mode":
				setProfileSettings((prev) => {
					return { ...profileSettings, compactMode: !prev.compactMode };
				});
				break;
			case "shareable":
				setUrlIsVisible((prev) => !prev);
				setProfileSettings((prev) => {
					return { ...profileSettings, userIsSharing: !prev.userIsSharing };
				});
				break;
			default:
		}
	};

	const handleSaveClick = (e) => {
		e.preventDefault();
		props.onSave(profileSettings);
	};

	const handleUrlCopy = () => {
		navigator.clipboard.writeText("https://starwarschecklist.com/shared/" + props.username);
		props.handleShowNotification("URL copied to clipboard");
	};

	const inputHandler = useCallback((id, value, isValid, matchAgainst) => {
		dispatch({
			type: "INPUT_CHANGE",
			value: value,
			isValid: isValid,
			inputId: id
		});
		switch (id) {
			case "password":
				setShowPasswordError(value !== matchAgainst);
				setPassword(value);
				break;
			case "password-confirm":
				setShowPasswordError(value !== matchAgainst);
				setPasswordConfirm(value);
				setProfileSettings({ ...profileSettings, password: value });
				break;
			default:
		}
	}, []);

	return (
		<Overlay onClose={props.closeProfile} className="profile">
			<h2>Settings for {props.username}</h2>
			<form action="" onSubmit={handleSaveClick}>
				<Input
					element="input"
					type="checkbox"
					label="Compact mode"
					checked={profileSettings.compactMode}
					id="compact-mode"
					value="Enabled"
					showLabel={false}
					onChange={handleSettingsChange}
				/>
				<Input
					element="input"
					type="checkbox"
					label="Allow your collection to be shared with others"
					checked={profileSettings.userIsSharing}
					id="shareable"
					value=""
					showLabel={false}
					onChange={handleSettingsChange}
				/>
				{urlIsVisible && (
					<div className="shareable-url-cont">
						<Input
							element="input"
							type="text"
							label="Your shareable URL:"
							id="shareable-url"
							value={"https://starwarschecklist.com/shared/" + props.username}
							className={profileSettings.userIsSharing && "active"}
							showLabel={true}
							readOnly={true}
						/>
						<i className="fa-regular fa-copy" onClick={handleUrlCopy}></i>
					</div>
				)}
				<Input className="read-only-input" element="input" type="text" label="Username" value={props.username} readOnly={true} />
				<Input
					element="input"
					type="password"
					label="Change your password"
					id="password"
					value=""
					matchAgainst={passwordConfirm}
					validate
					isValidStart={false}
					validators={[VALIDATOR_MINLENGTH(5)]}
					errorText=" Must be at least 5 characters long"
					onInput={inputHandler}
				/>
				<Input
					element="input"
					type="password"
					label="Retype your new password"
					id="password-confirm"
					value=""
					matchAgainst={password}
					validate
					isValidStart={false}
					validators={[]}
					errorText=" Passwords don't match"
					showPasswordError={showPasswordError}
					onInput={inputHandler}
				/>
				{props.profileError && <span className="error-text"> {props.profileError}</span>}
				<div className="edit-save-cancel">
					<div>
						<input type="submit" value="Submit" disabled={showPasswordError} />
						<button className="cancel" onClick={props.closeProfile}>
							Cancel
						</button>
					</div>
				</div>
			</form>
		</Overlay>
	);
};

export default Profile;
