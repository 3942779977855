import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import { AuthContext } from "../../context/auth-context";
import starterData from "../../starterData.json";
import BigImage from "../modals/BigImage";
import Header from "./Header";
import Container from "./Container";
import Login from "../modals/forms/Login";
import ForgotPassword from "../modals/forms/ForgotPassword";
import ResetPassword from "../modals/forms/ResetPassword";
import UserSignUp from "../modals/forms/UserSignUp";
import FilterContainer from "../controls/FilterContainer";
import AddForm from "../modals/forms/AddForm";
import AddVariantForm from "../modals/forms/AddVariantForm";
import AddDuplicateForm from "../modals/forms/AddDuplicateForm";
import Variants from "../modals/Variants";
import Duplicates from "../modals/Duplicates";
import Footer from "./Footer";
import Notification from "../elements/Notification";
import Spinner from "../elements/Spinner";
import CollectionSelect from "../controls/CollectionSelect";
import Profile from "../modals/forms/Profile";

const Wrapper = (props) => {
	const navigate = useNavigate();

	// Mode (used to compare when props.mode has changed)
	const [mode, setMode] = useState(props.mode);

	// Form errors
	const [signupError, setSignupError] = useState(null);
	const [loginError, setLoginError] = useState(null);
	const [profileError, setProfileError] = useState(null);
	const [forgotPwFormError, setForgotPwFormError] = useState(null);
	const [resetPwFormError, setResetPwFormError] = useState(null);

	// Set up headers config
	const config = {
		headers: {
			Authorization: "Bearer " + props.token,
			starterdata: props.username === "starterdata" ? true : false
		}
	};

	// Main data
	const [mainData, setMainData] = useState(props.data);

	// Totals
	const [owned, setOwned] = useState([...mainData].filter((item) => item.owned));
	const [figures, setFigures] = useState([...mainData].filter((item) => item.scope === "base"));
	const [creatures, setCreatures] = useState([...mainData].filter((item) => item.scope === "creatures"));
	const [vehicles, setVehicles] = useState([...mainData].filter((item) => item.scope === "vehicles"));
	const [playsets, setPlaysets] = useState([...mainData].filter((item) => item.scope === "playsets"));
	const [minirigs, setMinirigs] = useState([...mainData].filter((item) => item.scope === "minirigs"));

	// Owned data
	const [notOwned, setNotOwned] = useState([...mainData].filter((item) => !item.owned));

	// Complete data
	const complete = [...mainData].filter((item) => {
		let ownedAccessories = item.accessories.filter((accessory) => {
			return accessory.owned;
		});
		return ownedAccessories.length === item.accessories.length;
	});
	const incomplete = [...mainData].filter((item) => ![...complete].includes(item));

	const completeAndOwned = [...owned].filter((item) => {
		let ownedAccessories = item.accessories.filter((accessory) => {
			return accessory.owned;
		});
		return ownedAccessories.length === item.accessories.length;
	});
	const incompleteAndOwned = [...owned].filter((item) => ![...complete].includes(item));

	// Wanted data
	const wanted = [...mainData].filter((item) => item.wantList);
	const notWanted = [...mainData].filter((item) => !item.wantList);

	// Has variants data
	const hasVariants = [...mainData].filter((item) => item.variants.length > 0);
	const hasNoVariants = [...mainData].filter((item) => item.variants.length === 0);

	// Replace data
	const noExclude = [...mainData].filter((item) => !item.replace);
	const yesExclude = [...mainData].filter((item) => item.replace);

	// Debut cardback data
	const cb12 = [...mainData].filter((item) => item.debutCardback === "sw-12");
	const cb20 = [...mainData].filter((item) => item.debutCardback === "sw-20");
	const cb21 = [...mainData].filter((item) => item.debutCardback === "sw-21");
	const cb31 = [...mainData].filter((item) => item.debutCardback === "esb-31");
	const cb32 = [...mainData].filter((item) => item.debutCardback === "esb-32");
	const cb41 = [...mainData].filter((item) => item.debutCardback === "esb-41");
	const cb45 = [...mainData].filter((item) => item.debutCardback === "esb-45");
	const cb47 = [...mainData].filter((item) => item.debutCardback === "esb-47");
	const cb48 = [...mainData].filter((item) => item.debutCardback === "esb-48");
	const cb65 = [...mainData].filter((item) => item.debutCardback === "rotj-65");
	const cb77 = [...mainData].filter((item) => item.debutCardback === "rotj-77");
	const cb79 = [...mainData].filter((item) => item.debutCardback === "rotj-79");
	const cb92 = [...mainData].filter((item) => item.debutCardback === "potf-92");
	// Full cardback array
	const allDebutCardback = [
		"debut-cb-sw-12",
		"debut-cb-sw-20",
		"debut-cb-sw-21",
		"debut-cb-esb-31",
		"debut-cb-esb-32",
		"debut-cb-esb-41",
		"debut-cb-esb-45",
		"debut-cb-esb-47",
		"debut-cb-esb-48",
		"debut-cb-rotj-65",
		"debut-cb-rotj-77",
		"debut-cb-rotj-79",
		"debut-cb-potf-92"
	];

	// Year released data
	const yearReleased77 = [...mainData].filter((item) => item.yearReleased === "1977");
	const yearReleased78 = [...mainData].filter((item) => item.yearReleased === "1978");
	const yearReleased79 = [...mainData].filter((item) => item.yearReleased === "1979");
	const yearReleased80 = [...mainData].filter((item) => item.yearReleased === "1980");
	const yearReleased81 = [...mainData].filter((item) => item.yearReleased === "1981");
	const yearReleased82 = [...mainData].filter((item) => item.yearReleased === "1982");
	const yearReleased83 = [...mainData].filter((item) => item.yearReleased === "1983");
	const yearReleased84 = [...mainData].filter((item) => item.yearReleased === "1984");
	const yearReleased85 = [...mainData].filter((item) => item.yearReleased === "1985");
	// Full year released array
	const allYearReleased = [
		"year-released-1977",
		"year-released-1978",
		"year-released-1979",
		"year-released-1980",
		"year-released-1981",
		"year-released-1982",
		"year-released-1983",
		"year-released-1984",
		"year-released-1985"
	];

	// Condition data
	const condition1 = [...mainData].filter((item) => item.condition === "1");
	const condition2 = [...mainData].filter((item) => item.condition === "2");
	const condition3 = [...mainData].filter((item) => item.condition === "3");
	const condition4 = [...mainData].filter((item) => item.condition === "4");
	const condition5 = [...mainData].filter((item) => item.condition === "5");
	const condition6 = [...mainData].filter((item) => item.condition === "6");
	const condition7 = [...mainData].filter((item) => item.condition === "7");
	const condition8 = [...mainData].filter((item) => item.condition === "8");
	const condition9 = [...mainData].filter((item) => item.condition === "9");
	const condition10 = [...mainData].filter((item) => item.condition === "10");
	// Full condition array
	const allCondition = [
		"condition-1",
		"condition-2",
		"condition-3",
		"condition-4",
		"condition-5",
		"condition-6",
		"condition-7",
		"condition-8",
		"condition-9",
		"condition-10"
	];

	// Grade data
	const grade10 = [...mainData].filter((item) => item.grade === "10");
	const grade20 = [...mainData].filter((item) => item.grade === "20");
	const grade30 = [...mainData].filter((item) => item.grade === "30");
	const grade40 = [...mainData].filter((item) => item.grade === "40");
	const grade50 = [...mainData].filter((item) => item.grade === "50");
	const grade60 = [...mainData].filter((item) => item.grade === "60");
	const grade70 = [...mainData].filter((item) => item.grade === "70");
	const grade75 = [...mainData].filter((item) => item.grade === "75");
	const grade75Plus = [...mainData].filter((item) => item.grade === "75+");
	const grade80 = [...mainData].filter((item) => item.grade === "80");
	const grade80Plus = [...mainData].filter((item) => item.grade === "80+");
	const grade85 = [...mainData].filter((item) => item.grade === "85");
	const grade85Plus = [...mainData].filter((item) => item.grade === "85+");
	const grade90 = [...mainData].filter((item) => item.grade === "90");
	const grade90Plus = [...mainData].filter((item) => item.grade === "90+");
	const grade95 = [...mainData].filter((item) => item.grade === "95");
	const grade95Plus = [...mainData].filter((item) => item.grade === "95+");
	const grade100 = [...mainData].filter((item) => item.grade === "100");

	// Full grade array
	const allGrade = [
		"grade-10",
		"grade-20",
		"grade-30",
		"grade-40",
		"grade-50",
		"grade-60",
		"grade-70",
		"grade-75",
		"grade-75+",
		"grade-80",
		"grade-80+",
		"grade-85",
		"grade-85+",
		"grade-90",
		"grade-90+",
		"grade-95",
		"grade-95+",
		"grade-100"
	];

	// Company data
	const companyAFA = [...mainData].filter((item) => item.company === "AFA");
	const companyCAS = [...mainData].filter((item) => item.company === "CAS");
	const companyUKG = [...mainData].filter((item) => item.company === "UKG");
	// Full company array
	const allCompany = ["company-afa", "company-cas", "company-ukg"];

	// Cardback owned data
	const cbOwned12 = [...mainData].filter((item) => item.cardbackOwned === "sw-12");
	const cbOwned20 = [...mainData].filter((item) => item.cardbackOwned === "sw-20");
	const cbOwned21 = [...mainData].filter((item) => item.cardbackOwned === "sw-21");
	const cbOwned31 = [...mainData].filter((item) => item.cardbackOwned === "esb-31");
	const cbOwned32 = [...mainData].filter((item) => item.cardbackOwned === "esb-32");
	const cbOwned41 = [...mainData].filter((item) => item.cardbackOwned === "esb-41");
	const cbOwned45 = [...mainData].filter((item) => item.cardbackOwned === "esb-45");
	const cbOwned47 = [...mainData].filter((item) => item.cardbackOwned === "esb-47");
	const cbOwned48 = [...mainData].filter((item) => item.cardbackOwned === "esb-48");
	const cbOwned65 = [...mainData].filter((item) => item.cardbackOwned === "rotj-65");
	const cbOwned77 = [...mainData].filter((item) => item.cardbackOwned === "rotj-77");
	const cbOwned79 = [...mainData].filter((item) => item.cardbackOwned === "rotj-79");
	const cbOwned92 = [...mainData].filter((item) => item.cardbackOwned === "potf-92");
	const cbOwnedTriLogo = [...mainData].filter((item) => item.cardbackOwned === "tri-logo");
	// Full cardback owned array
	const allCardbackOwned = [
		"cb-own-sw-12",
		"cb-own-sw-20",
		"cb-own-sw-21",
		"cb-own-esb-31",
		"cb-own-esb-32",
		"cb-own-esb-41",
		"cb-own-esb-45",
		"cb-own-esb-47",
		"cb-own-esb-48",
		"cb-own-rotj-65",
		"cb-own-rotj-77",
		"cb-own-rotj-79",
		"cb-own-potf-92",
		"cb-own-tri-logo"
	];

	// For input two-way binding
	const [ownedFilter, setOwnedFilter] = useState("both-owned");
	const [excludeFilter, setExcludeFilter] = useState("both-exclude");
	const [wantListFilter, setWantListFilter] = useState("want-list-both");
	const [completeFilter, setCompleteFilter] = useState("both-complete");
	const [scope, setScope] = useState(props.localStorageScope ? props.localStorageScope : "base");
	const [searchInput, setSearchInput] = useState("");
	const [debutCardbackFilter, setDebutCardbackFilter] = useState(allDebutCardback);
	const [hasVariantsFilter, setHasVariantsFilter] = useState("both-has-variants");
	const [yearReleasedFilter, setYearReleasedFilter] = useState(allYearReleased);
	const [conditionFilter, setConditionFilter] = useState(allCondition);
	const [gradeFilter, setGradeFilter] = useState(allGrade);
	const [companyFilter, setCompanyFilter] = useState(allCompany);
	const [cardbackOwnedFilter, setCardbackOwnedFilter] = useState(allCardbackOwned);

	// For showing/hiding things
	const [completeOptionsVisible, setCompleteOptionsVisible] = useState(false);
	const [addItemFormVisible, setAddItemFormVisible] = useState(false);
	const [addVariantFormVisible, setAddVariantFormVisible] = useState(false);
	const [addDuplicateFormVisible, setAddDuplicateFormVisible] = useState(false);
	const [showVariants, setShowVariants] = useState(false);
	const [showDuplicates, setShowDuplicates] = useState(false);
	const [showLoginForm, setShowLoginForm] = useState(false);
	const [showUserSignUpForm, setShowUserSignUpForm] = useState(false);
	const [notificationActive, setNotificationActive] = useState(false);
	const [notificationMessage, setNotificationMessage] = useState("");
	const [bigImage, setBigImage] = useState([false, "", ""]);
	const [showProfile, setShowProfile] = useState(false);
	const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);
	const [showResetPasswordForm, setShowResetPasswordForm] = useState(props.passwordReset);

	// For displaying Variants data
	const [variantsData, setVariantsData] = useState([]);
	const [variantsParentData, setVariantsParentData] = useState({});

	// For displaying Duplicates data
	const [duplicatesData, setDuplicatesData] = useState([]);
	const [duplicatesParentData, setDuplicatesParentData] = useState({});

	// For compact toggle
	const [compact, setCompact] = useState(props.compactMode);

	// For userIsSharing toggle
	const [userIsSharing, setUserIsSharing] = useState(props.userIsSharing);

	// Data sets for filtering
	const [ownedData, setOwnedData] = useState([...mainData]);
	const [wantListData, setWantListData] = useState([...mainData]);
	const [completeData, setCompleteData] = useState([...mainData]);
	const [subCollectionData, setSubCollectionData] = useState(figures);
	const [excludeData, setExcludeData] = useState([...mainData]);
	const [debutCardbackData, setDebutCardbackData] = useState([...mainData]);
	const [hasVariantsData, setHasVariantsData] = useState([...mainData]);
	const [yearReleasedData, setYearReleasedData] = useState([...mainData]);
	const [conditionData, setConditionData] = useState([...mainData]);
	const [gradeData, setGradeData] = useState([...mainData]);
	const [companyData, setCompanyData] = useState([...mainData]);
	const [cardbackOwnedData, setCardbackOwnedData] = useState([...mainData]);

	// An array of all the above data sets. Be sure to add any new data sets to this array. This is for search functionality.
	const data = [
		subCollectionData,
		ownedData,
		wantListData,
		completeData,
		debutCardbackData,
		excludeData,
		hasVariantsData,
		yearReleasedData,
		conditionData,
		gradeData,
		companyData,
		cardbackOwnedData
	];

	// Search
	const [searchData, setSearchData] = useState([...mainData]);

	// Official figures (owned + no exclude)
	const [officialItems, setOfficialItems] = useState([...owned].filter((item) => [...noExclude].includes(item)));
	const [baseOfficial, setBaseOfficial] = useState([...officialItems].filter((item) => item.scope === "base"));
	const [creaturesOfficial, setCreaturesOfficial] = useState(
		[...officialItems].filter((item) => item.scope === "creatures")
	);
	const [vehiclesOfficial, setVehiclesOfficial] = useState(
		[...officialItems].filter((item) => item.scope === "vehicles")
	);
	const [playsetsOfficial, setPlaysetsOfficial] = useState(
		[...officialItems].filter((item) => item.scope === "playsets")
	);
	const [minirigsOfficial, setMinirigsOfficial] = useState(
		[...officialItems].filter((item) => item.scope === "minirigs")
	);

	// Used to set the filter correctly after a card update
	const [updatedFields, setUpdatedFields] = useState([]);

	// Filter state
	const [filteredData, setFilteredData] = useState(figures);

	// Owned of filteredData
	const [ownedOfFiltered, setOwnedOfFiltered] = useState({});

	// Only works using useEffect along with the convoluted filter technique
	useEffect(() => {
		setOwnedOfFiltered(
			[...filteredData].filter((el) => {
				return officialItems.find((element) => {
					return element._id === el._id;
				});
			})
		);
	}, [filteredData, officialItems]);

	// Redirect to /username if user is logged in, otherwise redirect to /
	const location = useLocation();

	// Set key data for initial render and if props.data has changed (e.g. when mode has changed or when a card is edited)
	useEffect(() => {
		const owned = [...props.data].filter((item) => item.owned);
		const noExclude = [...owned].filter((item) => item.replace === false);
		const officialItems = [...owned].filter((item) => [...noExclude].includes(item));
		const figures = [...props.data].filter((item) => item.scope === "base");
		const creatures = [...props.data].filter((item) => item.scope === "creatures");
		const vehicles = [...props.data].filter((item) => item.scope === "vehicles");
		const playsets = [...props.data].filter((item) => item.scope === "playsets");
		const minirigs = [...props.data].filter((item) => item.scope === "minirigs");
		setCompact(props.username === "starterdata" ? false : props.compactMode);
		setUserIsSharing(props.username === "starterdata" ? false : props.userIsSharing);
		setMainData(props.data);
		setOwned(owned);
		setNotOwned([...props.data].filter((item) => !item.owned));
		setFigures(figures);
		setCreatures(creatures);
		setVehicles(vehicles);
		setPlaysets(playsets);
		setMinirigs(minirigs);
		setOfficialItems(officialItems);
		setBaseOfficial([...officialItems].filter((item) => item.scope === "base"));
		setCreaturesOfficial([...officialItems].filter((item) => item.scope === "creatures"));
		setVehiclesOfficial([...officialItems].filter((item) => item.scope === "vehicles"));
		setPlaysetsOfficial([...officialItems].filter((item) => item.scope === "playsets"));
		setMinirigsOfficial([...officialItems].filter((item) => item.scope === "minirigs"));

		// Only do the following on the initial render or when mode is changed
		if (filteredData.length === 0 || props.mode !== mode) {
			setMode(props.mode); // Get mode in sync with props.mode
			setOwnedData([...props.data]);
			setWantListData([...props.data]);
			setCompleteData([...props.data]);
			setSubCollectionData(
				props.localStorageScope === "base"
					? figures
					: props.localStorageScope === "creatures"
					? creatures
					: props.localStorageScope === "vehicles"
					? vehicles
					: props.localStorageScope === "playsets"
					? playsets
					: props.localStorageScope === "minirigs"
					? minirigs
					: figures
			);
			setExcludeData([...props.data]);
			setDebutCardbackData([...props.data]);
			setSearchData([...props.data]);
			setHasVariantsData([...props.data]);
			setYearReleasedData([...props.data]);
			setConditionData([...props.data]);
			setGradeData([...props.data]);
			setCompanyData([...props.data]);
			setCardbackOwnedData([...props.data]);
			switch (scope) {
				case "base":
					setFilteredData(figures);
					break;
				case "creatures":
					setFilteredData(creatures);
					break;
				case "vehicles":
					setFilteredData(vehicles);
					break;
				case "playsets":
					setFilteredData(playsets);
					break;
				case "minirigs":
					setFilteredData(minirigs);
					break;
				default:
			}
		}

		// Redirect to /username if user is logged in, otherwise redirect to /
		// First check if we're using a shared user link or password reset link
		if (!props.sharedLink && !props.passwordReset) {
			if (props.token) {
				// Redirect to /username if you're not already there
				if (location.pathname !== "/" + props.username) {
					navigate("/" + props.username);
				}
			} else {
				// Redirect to / if you're not already there
				if (location.pathname !== "/") {
					navigate("/");
				}
			}
		}
	}, [props.data]);

	// Call filterHandler when an update has been made
	useEffect(() => {
		if (updatedFields.length > 0) {
			updatedFields.forEach((item) => {
				filterHandler(null, item);
			});
		}
	}, [mainData]);

	// Sorting function
	const sortTheData = (array, key, dir) => {
		if (dir === "ASC") {
			return [...array].sort((a, b) => a[key] - b[key]);
		} else if (dir === "DESC") {
			return [...array].sort((a, b) => b[key] - a[key]);
		}
	};

	// Reset everything (this is different from handleRefresh as it resets states to their initial values)
	const handleFullReset = (newData) => {
		// Reset the main data states if a user logged in or out
		if (newData) {
			const newOwned = [...newData].filter((item) => item.owned);
			const newNoExclude = [...newOwned].filter((item) => item.replace === false);
			const newFigures = [...newData].filter((item) => item.scope === "base");
			setOfficialItems([...newOwned].filter((item) => [...newNoExclude].includes(item)));
			setOwned(newOwned);
			setNotOwned([...newData].filter((item) => !item.owned));
			setFigures(newFigures);
			setSubCollectionData(newFigures);
			setCreatures([...newData].filter((item) => item.scope === "creatures"));
			setVehicles([...newData].filter((item) => item.scope === "vehicles"));
			setPlaysets([...newData].filter((item) => item.scope === "playsets"));
			setMinirigs([...newData].filter((item) => item.scope === "minirigs"));
			// Reset the counts
			resetCounts(newData);
		}
		// Then reset all the remaining data states
		setFilteredData(newData ? newData.filter((item) => item.scope === "base") : subCollectionData);
		setOwnedData(newData ? newData : [...mainData]);
		setCompleteData(newData ? newData : [...mainData]);
		setExcludeData(newData ? newData : [...mainData]);
		setDebutCardbackData(newData ? newData : [...mainData]);
		setSearchData(newData ? newData : [...mainData]);
		setWantListData(newData ? newData : [...mainData]);
		setHasVariantsData(newData ? newData : [...mainData]);
		setYearReleasedData(newData ? newData : [...mainData]);
		setConditionData(newData ? newData : [...mainData]);
		setGradeData(newData ? newData : [...mainData]);
		setCompanyData(newData ? newData : [...mainData]);
		setCardbackOwnedData(newData ? newData : [...mainData]);
		setSearchInput("");
		setDebutCardbackFilter(allDebutCardback);
		setOwnedFilter("both-owned");
		setWantListFilter("want-list-both");
		setExcludeFilter("both-exclude");
		setCompleteFilter("both-complete");
		setHasVariantsFilter("both-has-variants");
		setCompleteOptionsVisible(false);
		setYearReleasedFilter(allYearReleased);
		setConditionFilter(allCondition);
		setGradeFilter(allGrade);
		setCompanyFilter(allCompany);
		setCardbackOwnedFilter(allCardbackOwned);
	};

	// Refresh component (re-renders the component with the new data included) after edit
	const handleRefresh = (cardData, add, variant, update, markedComplete, duplicate) => {
		const oldVariantsParentData = { ...variantsParentData };
		let newVariantsParentData;
		let newDuplicatesParentData;
		if (variant) {
			if (add) {
				setVariantsData((prev) => [...prev, cardData]);
				variantsParentData.variants = [...variantsParentData.variants, cardData];
			} else if (update) {
				const indexOfChild = variantsData.findIndex((item) => item._id === cardData._id);
				variantsParentData.variants[indexOfChild] = cardData;
			} else {
				// Delete
				setVariantsData((prev) => prev.filter((item) => item._id !== cardData._id));
				const newVariantData = variantsParentData.variants.filter((item) => item._id !== cardData._id);
				variantsParentData.variants = newVariantData;
				newVariantsParentData = variantsParentData;
				setVariantsParentData(newVariantsParentData);
			}
		} else if (duplicate) {
			if (add) {
				setDuplicatesData((prev) => [...prev, cardData]);
				duplicatesParentData.duplicates = [...duplicatesParentData.duplicates, cardData];
			} else if (update) {
				const indexOfChild = duplicatesData.findIndex((item) => item._id === cardData._id);
				duplicatesParentData.duplicates[indexOfChild] = cardData;
			} else {
				// Delete
				setDuplicatesData((prev) => prev.filter((item) => item._id !== cardData._id));
				const newDuplicatesData = duplicatesParentData.duplicates.filter((item) => item._id !== cardData._id);
				duplicatesParentData.duplicates = newDuplicatesData;
				newDuplicatesParentData = duplicatesParentData;
				setDuplicatesParentData(newDuplicatesParentData);
			}
		} else {
			if (cardData) {
				if (add) {
					setFilteredData((prev) => [...prev, cardData]);
				} else if (update) {
					// Do nothing, handled below
				} else {
					// Delete
					setFilteredData((prev) => prev.filter((item) => item._id !== cardData._id));
				}
			}
		}
		axios.get(process.env.REACT_APP_SERVER_DOMAIN + "/api/users/" + props.username, config).then((res) => {
			let newData;
			switch (props.mode) {
				case "loose":
					newData = res.data.loose;
					break;
				case "loose-graded":
					newData = res.data.looseGraded;
					break;
				case "moc":
					newData = res.data.moc;
					break;
				case "moc-graded":
					newData = res.data.mocGraded;
					break;
				default:
			}
			resetCounts(newData);

			// Call findUpdatedFields if necessary in order to trigger a re-filter
			if (variant || (update && !duplicate)) {
				props.getData(props.mode);
				if (variant) {
					findUpdatedFields(newVariantsParentData, markedComplete, oldVariantsParentData);
				} else {
					findUpdatedFields(cardData, markedComplete);
				}
			}
		});
	};

	// Reset the counts
	const resetCounts = (newData) => {
		const newOwned = [...newData].filter((item) => item.owned);
		const newNoExclude = [...newOwned].filter((item) => item.replace === false);
		const newOfficialItems = [...newOwned].filter((item) => [...newNoExclude].includes(item));
		setOfficialItems(newOfficialItems);
		setBaseOfficial([...newOfficialItems].filter((item) => item.scope === "base"));
		setCreaturesOfficial([...newOfficialItems].filter((item) => item.scope === "creatures"));
		setVehiclesOfficial([...newOfficialItems].filter((item) => item.scope === "vehicles"));
		setPlaysetsOfficial([...newOfficialItems].filter((item) => item.scope === "playsets"));
		setMinirigsOfficial([...newOfficialItems].filter((item) => item.scope === "minirigs"));
	};

	// Find which fields were updated and set the setUpdatedFields state
	const findUpdatedFields = (cardData, markedComplete, oldVariantsParentData) => {
		let originalCard = oldVariantsParentData || [...props.data].filter((item) => item._id === cardData._id)[0];
		for (const item in cardData) {
			if (item === "owned" && cardData.owned !== originalCard.owned) {
				setUpdatedFields([...updatedFields, ["owned"]]);
			}
			if (item === "condition" && cardData.condition !== originalCard.condition) {
				setUpdatedFields([...updatedFields, ["condition", "condition-" + cardData.condition, cardData._id]]);
			}
			if (item === "replace" && cardData.replace !== originalCard.replace) {
				setUpdatedFields([...updatedFields, ["exclude"]]);
			}
			if (item === "wantList" && cardData.wantList !== originalCard.wantList) {
				setUpdatedFields([...updatedFields, ["wantList"]]);
			}
			if (item === "company" && cardData.company !== originalCard.company) {
				setUpdatedFields([...updatedFields, ["company", "company-" + cardData.company.toLowerCase(), cardData._id]]);
			}
			if (item === "grade" && cardData.grade !== originalCard.grade) {
				setUpdatedFields([...updatedFields, ["grade", "grade-" + cardData.grade, cardData._id]]);
			}
			if (item === "cardbackOwned" && cardData.cardbackOwned !== originalCard.cardbackOwned) {
				setUpdatedFields([...updatedFields, ["cardbackOwned", "cb-own-" + cardData.cardbackOwned, cardData._id]]);
			}

			// Variants
			if (item === "variants") {
				// Determine if the variants have changed
				if (originalCard.variants.length !== cardData.variants.length) {
					setUpdatedFields([...updatedFields, ["variants"]]);
				}
			}

			// Complete (only applies to Loose)
			if (item === "accessories" && mode === "loose") {
				// Determine if the accessories have changed
				const origAcc = originalCard.accessories;
				const newAcc = cardData.accessories;
				let hasChanged = false;
				if (markedComplete) {
					hasChanged = true;
				} else {
					[...origAcc].forEach((item, index) => {
						if (origAcc[index].owned !== newAcc[index].owned) {
							hasChanged = true;
						}
					});
				}
				if (hasChanged === true) {
					setUpdatedFields([...updatedFields, ["complete"]]);
				}
			}
		}
	};

	// Filter function
	const filterHandler = (e, updatedField) => {
		let value;
		if (e) {
			value = e.currentTarget.id;
		} else {
			// Check if the filter was triggered by an update, and set the value accordingly
			if (updatedField) {
				switch (updatedField[0]) {
					case "owned":
						value = ownedFilter;
						break;
					case "condition":
						value = updatedField[1];
						break;
					case "exclude":
						value = excludeFilter;
						break;
					case "wantList":
						value = wantListFilter;
						break;
					case "company":
						value = updatedField[1];
						break;
					case "grade":
						value = updatedField[1];
						break;
					case "cardbackOwned":
						value = updatedField[1];
						break;
					case "complete":
						value = completeFilter;
						break;
					case "variants":
						value = hasVariantsFilter;
						break;
					default:
						value = ownedFilter;
				}
			}
		}

		let array = [];
		let filterAgainst = [];

		// Exit the function if an update is made to complete (accessories) while not-owned is selected
		if (!e & (ownedFilter === "not-owned") & (value === "both-complete")) {
			return;
		}

		// See which owned filter is currently active - this is just for when an update is made to complete (accessories)
		let completeArray;
		let incompleteArray;
		if (ownedFilter === "owned") {
			completeArray = completeAndOwned;
			incompleteArray = incompleteAndOwned;
		} else if (ownedFilter === "both-owned") {
			completeArray = complete;
			incompleteArray = incomplete;
		}

		// First determine the correct dataset to use for the filter
		switch (value) {
			case "complete":
				if (ownedFilter === "not-owned" || ownedFilter === "both-owned") {
					break;
				}
				array = completeArray || complete;
				break;
			case "incomplete":
				if (ownedFilter === "not-owned" || ownedFilter === "both-owned") {
					break;
				}
				array = incompleteArray || incomplete;
				break;
			case "both-complete":
				try {
					array = [...completeArray, ...incompleteArray];
				} catch (err) {
					array = [...complete, ...incomplete];
				}
				break;
			case "base-collection":
				array = figures;
				setScope("base");
				localStorage.setItem("scope", "base");
				break;
			case "creatures-collection":
				array = creatures;
				setScope("creatures");
				localStorage.setItem("scope", "creatures");
				break;
			case "vehicles-collection":
				array = vehicles;
				setScope("vehicles");
				localStorage.setItem("scope", "vehicles");
				break;
			case "playsets-collection":
				array = playsets;
				setScope("playsets");
				localStorage.setItem("scope", "playsets");
				break;
			case "minirigs-collection":
				array = minirigs;
				setScope("minirigs");
				localStorage.setItem("scope", "minirigs");
				break;
			case "owned":
				array = owned;
				setCompleteOptionsVisible(true);
				break;
			case "not-owned":
			case "both-owned":
				if (value === "not-owned") {
					array = notOwned;
				} else if (value === "both-owned") {
					array = [...owned, ...notOwned];
				}
				setCompleteOptionsVisible(false);
				setCompleteFilter("both-complete");
				setExcludeFilter("both-exclude");
				setCompleteData([...mainData]);
				setExcludeData([...mainData]);
				setConditionFilter(allCondition);
				setConditionData([...mainData]);
				setGradeFilter(allGrade);
				setGradeData([...mainData]);
				setCompanyFilter(allCompany);
				setCompanyData([...mainData]);
				setCardbackOwnedFilter(allCardbackOwned);
				setCardbackOwnedData([...mainData]);
				break;
			case "want-list-yes":
				array = wanted;
				break;
			case "want-list-no":
				array = notWanted;
				break;
			case "want-list-both":
				array = [...wanted, ...notWanted];
				break;
			case "yes-exclude":
				array = yesExclude;
				break;
			case "no-exclude":
				array = noExclude;
				break;
			case "both-exclude":
				array = [...yesExclude, ...noExclude];
				break;
			case "yes-has-variants":
				array = hasVariants;
				break;
			case "no-has-variants":
				array = hasNoVariants;
				break;
			case "both-has-variants":
				array = [...hasVariants, ...hasNoVariants];
				break;
			default:
		}

		if (value.includes("debut-cb")) {
			let cardback;
			switch (value) {
				case "debut-cb-sw-12":
					cardback = cb12;
					break;
				case "debut-cb-sw-20":
					cardback = cb20;
					break;
				case "debut-cb-sw-21":
					cardback = cb21;
					break;
				case "debut-cb-esb-31":
					cardback = cb31;
					break;
				case "debut-cb-esb-32":
					cardback = cb32;
					break;
				case "debut-cb-esb-41":
					cardback = cb41;
					break;
				case "debut-cb-esb-45":
					cardback = cb45;
					break;
				case "debut-cb-esb-47":
					cardback = cb47;
					break;
				case "debut-cb-esb-48":
					cardback = cb48;
					break;
				case "debut-cb-rotj-65":
					cardback = cb65;
					break;
				case "debut-cb-rotj-77":
					cardback = cb77;
					break;
				case "debut-cb-rotj-79":
					cardback = cb79;
					break;
				case "debut-cb-potf-92":
					cardback = cb92;
					break;
				default:
			}
			if (e) {
				if (!e.target.classList.contains("active")) {
					setDebutCardbackFilter([...debutCardbackFilter, value]);
					array = [...debutCardbackData, ...cardback];
				} else {
					setDebutCardbackFilter([...debutCardbackFilter].filter((item) => item !== value));
					array = [...debutCardbackData].filter((item) => {
						return !cardback.find((element) => {
							return element._id === item._id;
						});
					});
				}
			} else {
				// Check if value should be filtered out
				if (![...debutCardbackFilter].includes(value)) {
					const id = updatedField[2];
					array = [...debutCardbackData].filter((item) => item._id !== id);
				} else {
					return;
				}
			}
			setDebutCardbackData(array);
			filterAgainst.push(
				subCollectionData,
				ownedData,
				wantListData,
				completeData,
				excludeData,
				hasVariantsData,
				yearReleasedData,
				gradeData,
				companyData,
				cardbackOwnedData
			);
		}

		if (value.includes("year-released")) {
			let year;
			switch (value) {
				case "year-released-1977":
					year = yearReleased77;
					break;
				case "year-released-1978":
					year = yearReleased78;
					break;
				case "year-released-1979":
					year = yearReleased79;
					break;
				case "year-released-1980":
					year = yearReleased80;
					break;
				case "year-released-1981":
					year = yearReleased81;
					break;
				case "year-released-1982":
					year = yearReleased82;
					break;
				case "year-released-1983":
					year = yearReleased83;
					break;
				case "year-released-1984":
					year = yearReleased84;
					break;
				case "year-released-1985":
					year = yearReleased85;
					break;
				default:
			}
			if (e) {
				if (!e.target.classList.contains("active")) {
					setYearReleasedFilter([...yearReleasedFilter, value]);
					array = [...yearReleasedData, ...year];
				} else {
					setYearReleasedFilter([...yearReleasedFilter].filter((item) => item !== value));
					array = [...yearReleasedData].filter((item) => {
						return !year.find((element) => {
							return element._id === item._id;
						});
					});
				}
			} else {
				// Check if value should be filtered out
				if (![...yearReleasedFilter].includes(value)) {
					const id = updatedField[2];
					array = [...yearReleasedData].filter((item) => item._id !== id);
				} else {
					return;
				}
			}
			setYearReleasedData(array);
			filterAgainst.push(
				subCollectionData,
				ownedData,
				wantListData,
				completeData,
				excludeData,
				hasVariantsData,
				debutCardbackData,
				gradeData,
				companyData,
				cardbackOwnedData
			);
		}

		if (value.includes("condition")) {
			let condition;
			switch (value) {
				case "condition-1":
					condition = condition1;
					break;
				case "condition-2":
					condition = condition2;
					break;
				case "condition-3":
					condition = condition3;
					break;
				case "condition-4":
					condition = condition4;
					break;
				case "condition-5":
					condition = condition5;
					break;
				case "condition-6":
					condition = condition6;
					break;
				case "condition-7":
					condition = condition7;
					break;
				case "condition-8":
					condition = condition8;
					break;
				case "condition-9":
					condition = condition9;
					break;
				case "condition-10":
					condition = condition10;
					break;
				default:
			}

			if (e) {
				if (!e.target.classList.contains("active")) {
					setConditionFilter([...conditionFilter, value]);
					array = [...conditionData, ...condition];
				} else {
					setConditionFilter([...conditionFilter].filter((item) => item !== value));
					array = [...conditionData].filter((item) => {
						return !condition.find((element) => {
							return element._id === item._id;
						});
					});
				}
			} else {
				// Check if value should be filtered out
				if (![...conditionFilter].includes(value)) {
					const id = updatedField[2];
					array = [...conditionData].filter((item) => item._id !== id);
				} else {
					return;
				}
			}

			setConditionData(array);
			filterAgainst.push(
				subCollectionData,
				ownedData,
				wantListData,
				completeData,
				excludeData,
				hasVariantsData,
				debutCardbackData,
				gradeData,
				companyData,
				cardbackOwnedData
			);
		}

		if (value.includes("grade")) {
			let grade;
			switch (value) {
				case "grade-10":
					grade = grade10;
					break;
				case "grade-20":
					grade = grade20;
					break;
				case "grade-30":
					grade = grade30;
					break;
				case "grade-40":
					grade = grade40;
					break;
				case "grade-50":
					grade = grade50;
					break;
				case "grade-60":
					grade = grade60;
					break;
				case "grade-70":
					grade = grade70;
					break;
				case "grade-75":
					grade = grade75;
					break;
				case "grade-75+":
					grade = grade75Plus;
					break;
				case "grade-80":
					grade = grade80;
					break;
				case "grade-80+":
					grade = grade80Plus;
					break;
				case "grade-85":
					grade = grade85;
					break;
				case "grade-85+":
					grade = grade85Plus;
					break;
				case "grade-90":
					grade = grade90;
					break;
				case "grade-90+":
					grade = grade90Plus;
					break;
				case "grade-95":
					grade = grade95;
					break;
				case "grade-95+":
					grade = grade95Plus;
					break;
				case "grade-100":
					grade = grade100;
					break;
				default:
			}
			if (e) {
				if (!e.target.classList.contains("active")) {
					setGradeFilter([...gradeFilter, value]);
					array = [...gradeData, ...grade];
				} else {
					setGradeFilter([...gradeFilter].filter((item) => item !== value));
					array = [...gradeData].filter((item) => {
						return !grade.find((element) => {
							return element._id === item._id;
						});
					});
				}
			} else {
				// Check if value should be filtered out
				if (![...gradeFilter].includes(value)) {
					const id = updatedField[2];
					array = [...gradeData].filter((item) => item._id !== id);
				} else {
					return;
				}
			}
			setGradeData(array);
			filterAgainst.push(
				subCollectionData,
				ownedData,
				wantListData,
				completeData,
				excludeData,
				hasVariantsData,
				debutCardbackData,
				conditionData,
				companyData,
				cardbackOwnedData
			);
		}

		if (value.includes("company")) {
			let company;
			switch (value) {
				case "company-afa":
					company = companyAFA;
					break;
				case "company-cas":
					company = companyCAS;
					break;
				case "company-ukg":
					company = companyUKG;
					break;
				default:
			}

			if (e) {
				if (!e.target.classList.contains("active")) {
					setCompanyFilter([...companyFilter, value]);
					array = [...companyData, ...company];
				} else {
					setCompanyFilter([...companyFilter].filter((item) => item !== value));
					array = [...companyData].filter((item) => {
						return !company.find((element) => {
							return element._id === item._id;
						});
					});
				}
			} else {
				// Check if value should be filtered out
				if (![...companyFilter].includes(value)) {
					const id = updatedField[2];
					array = [...companyData].filter((item) => item._id !== id);
				} else {
					return;
				}
			}

			setCompanyData(array);
			filterAgainst.push(
				subCollectionData,
				ownedData,
				wantListData,
				completeData,
				excludeData,
				hasVariantsData,
				debutCardbackData,
				conditionData,
				gradeData,
				cardbackOwnedData
			);
		}

		if (value.includes("cb-own-")) {
			let cardbackOwned;
			switch (value) {
				case "cb-own-sw-12":
					cardbackOwned = cbOwned12;
					break;
				case "cb-own-sw-20":
					cardbackOwned = cbOwned20;
					break;
				case "cb-own-sw-21":
					cardbackOwned = cbOwned21;
					break;
				case "cb-own-esb-31":
					cardbackOwned = cbOwned31;
					break;
				case "cb-own-esb-32":
					cardbackOwned = cbOwned32;
					break;
				case "cb-own-esb-41":
					cardbackOwned = cbOwned41;
					break;
				case "cb-own-esb-45":
					cardbackOwned = cbOwned45;
					break;
				case "cb-own-esb-47":
					cardbackOwned = cbOwned47;
					break;
				case "cb-own-esb-48":
					cardbackOwned = cbOwned48;
					break;
				case "cb-own-rotj-65":
					cardbackOwned = cbOwned65;
					break;
				case "cb-own-rotj-77":
					cardbackOwned = cbOwned77;
					break;
				case "cb-own-rotj-79":
					cardbackOwned = cbOwned79;
					break;
				case "cb-own-potf-92":
					cardbackOwned = cbOwned92;
					break;
				case "cb-own-tri-logo":
					cardbackOwned = cbOwnedTriLogo;
					break;
				default:
			}
			if (e) {
				if (!e.target.classList.contains("active")) {
					setCardbackOwnedFilter([...cardbackOwnedFilter, value]);
					array = [...cardbackOwnedData, ...cardbackOwned];
				} else {
					setCardbackOwnedFilter([...cardbackOwnedFilter].filter((item) => item !== value));
					array = [...cardbackOwnedData].filter((item) => {
						return !cardbackOwned.find((element) => {
							return element._id === item._id;
						});
					});
				}
			} else {
				// Check if value should be filtered out
				if (![...cardbackOwnedFilter].includes(value)) {
					const id = updatedField[2];
					array = [...cardbackOwnedData].filter((item) => item._id !== id);
				} else {
					return;
				}
			}
			setCardbackOwnedData(array);
			filterAgainst.push(
				subCollectionData,
				ownedData,
				wantListData,
				completeData,
				excludeData,
				hasVariantsData,
				yearReleasedData,
				gradeData,
				companyData,
				gradeData
			);
		}

		// Next determine all the filters against which the dataset should be filtered
		if (value.includes("complete")) {
			filterAgainst.push(subCollectionData, wantListData, excludeData, hasVariantsData, yearReleasedData);
			if (scope === "base") {
				filterAgainst.push(debutCardbackData);
			}
			setCompleteFilter(value);
			setCompleteData(array);
		} else if (value.includes("collection")) {
			filterAgainst.push(ownedData, wantListData, hasVariantsData, yearReleasedData, debutCardbackData);
			if (completeOptionsVisible) {
				filterAgainst.push(completeData, excludeData);
			}
			setSubCollectionData(array);
		} else if (value.includes("exclude")) {
			filterAgainst.push(subCollectionData, wantListData, completeData, hasVariantsData, yearReleasedData);
			if (scope === "base") {
				filterAgainst.push(debutCardbackData);
			}
			setExcludeFilter(value);
			setExcludeData(array);
		} else if (value.includes("owned")) {
			filterAgainst.push(subCollectionData, wantListData, hasVariantsData, yearReleasedData);
			if (scope === "base") {
				filterAgainst.push(debutCardbackData);
			}
			setOwnedFilter(value);
			setOwnedData(array);
		} else if (value.includes("want-list")) {
			filterAgainst.push(ownedData, subCollectionData, debutCardbackData, hasVariantsData, yearReleasedData);
			if (completeOptionsVisible) {
				filterAgainst.push(completeData, excludeData);
			}
			setWantListFilter(value);
			setWantListData(array);
		} else if (value.includes("has-variants")) {
			filterAgainst.push(ownedData, subCollectionData, debutCardbackData, wantListData, yearReleasedData);
			if (completeOptionsVisible) {
				filterAgainst.push(completeData, excludeData);
			}
			setHasVariantsFilter(value);
			setHasVariantsData(array);
		}

		if (!value.includes("condition") && (props.mode === "loose" || props.mode === "moc")) {
			filterAgainst.push(conditionData);
		}

		if (props.mode === "loose-graded" || props.mode === "moc-graded") {
			if (!value.includes("grade")) {
				filterAgainst.push(gradeData);
			}
			if (!value.includes("company")) {
				filterAgainst.push(companyData);
			}
		}

		if (!value.includes("cb-own-") && scope === "base" && (props.mode === "moc" || props.mode === "moc-graded")) {
			filterAgainst.push(cardbackOwnedData);
		}

		let filter;
		for (let i = 0; i < filterAgainst.length; i++) {
			let newFilter = filter;
			if (i === 0) {
				newFilter = array;
			}
			// I'm using this more convoluted filtering here (and elsewhere) in order to match same items in the dataset even after the data has been reloaded, since two items in two different arrays will not work with "includes" even if they're identical
			filter = newFilter.filter((el) => {
				return filterAgainst[i].find((element) => {
					return element._id === el._id;
				});
			});
		}

		filter = filter.filter((el) => {
			return searchData.find((element) => {
				return element._id === el._id;
			});
		});
		setFilteredData(filter);
	};

	// Clear the search
	const handleClearSearch = () => {
		setSearchInput("");
		setSearchData([...mainData]);
		let filter;
		for (let i = 0; i < data.length; i++) {
			let newFilter = filter;
			if (i === 0) {
				newFilter = [...mainData];
			}
			filter = newFilter.filter((el) => {
				return data[i].find((element) => {
					return element._id === el._id;
				});
			});
		}
		setFilteredData(filter);
	};

	// Search
	const handleSearch = (e) => {
		setSearchInput(e.target.value);
		const formData = new FormData();
		formData.append("mode", props.mode);
		formData.append("shared", props.sharedLink);
		if (e.target.value !== "") {
			axios
				.put(
					process.env.REACT_APP_SERVER_DOMAIN +
						"/api/items/search/" +
						(props.sharedLink ? props.paramUser : props.username) +
						"/" +
						e.target.value,
					formData,
					config
				)
				.then((res) => {
					setSearchData(res.data);
					let filter;
					for (let i = 0; i < data.length; i++) {
						let newFilter = filter;
						if (i === 0) {
							newFilter = res.data;
						}
						filter = newFilter.filter((el) => {
							return data[i].find((element) => {
								return element._id === el._id;
							});
						});
					}
					setFilteredData(filter);
				})
				.catch((error) => {
					if (error.res) {
						console.log("Data :", error.res.data);
						console.log("Status :" + error.res.status);
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log("Error", error.message);
					}
				});
		} else {
			handleClearSearch();
		}
	};

	const handleShowAddForm = () => {
		setAddItemFormVisible((prev) => !prev);
	};

	const handleShowVariantForm = () => {
		if (props.username === "starterdata") {
			handleShowUserSignUpForm();
		} else {
			setAddVariantFormVisible((prev) => !prev);
		}
	};

	const handleShowDuplicateForm = () => {
		setAddDuplicateFormVisible((prev) => !prev);
	};

	const handleAddNewItem = (newData, formIsValid) => {
		axios
			.put(process.env.REACT_APP_SERVER_DOMAIN + "/api/items/" + props.username, [newData, formIsValid], config)
			.then((res) => {
				handleShowAddForm();
				handleShowNotification("New item added");
				// Grab the new item data from the response to update the card's full data without having to refresh
				const newItem = res.data.loose[res.data.loose.length - 1];
				handleRefresh(newItem, true);
			});
	};

	const handleAddNewVariant = (newData, image, formIsValid) => {
		const formData = new FormData();
		formData.append("newData", JSON.stringify(newData));
		formData.append("formIsValid", formIsValid);
		formData.append("mode", props.mode);
		if (image) {
			formData.append("image", image);
		}

		axios
			.put(
				process.env.REACT_APP_SERVER_DOMAIN + "/api/items/addvariant/" + props.username + "/" + variantsParentData._id,
				formData,
				config
			)
			.then((res) => {
				handleShowVariantForm();
				handleShowNotification("New variant added");
				// Grab the new item data from the response to update the card's full data without having to refresh
				const newItemData = res.data.filter((item) => item._id === variantsParentData._id);
				const newItemVariants = newItemData[0].variants;
				const newItem = newItemVariants[newItemVariants.length - 1];
				handleRefresh(newItem, true, true);
			});
	};

	const handleAddNewDuplicate = (newData, image, formIsValid) => {
		const formData = new FormData();
		formData.append("newData", JSON.stringify(newData));
		formData.append("formIsValid", formIsValid);
		formData.append("mode", props.mode);
		if (image) {
			formData.append("image", image);
		}

		axios
			.put(
				process.env.REACT_APP_SERVER_DOMAIN +
					"/api/items/addduplicate/" +
					props.username +
					"/" +
					duplicatesParentData._id,
				formData,
				config
			)
			.then((res) => {
				handleShowDuplicateForm();
				handleShowNotification("New duplicate added");
				// Grab the new item data from the response to update the card's full data without having to refresh
				const newItemData = res.data.filter((item) => item._id === duplicatesParentData._id);
				const newItemDuplicates = newItemData[0].duplicates || [];
				const newItem = newItemDuplicates[newItemDuplicates.length - 1];
				handleRefresh(newItem, true, false, false, false, true);
			});
	};

	const handleImageClick = (image, customImage) => {
		setBigImage([true, image, customImage]);
	};

	const handleCloseBigImage = () => {
		setBigImage([false, ""]);
	};

	const handleSetVariantsData = (variantData, parentData) => {
		setVariantsData(variantData);
		setVariantsParentData(parentData);
	};

	const handleSetDuplicatesData = (duplicateData, parentData) => {
		setDuplicatesData(duplicateData);
		setDuplicatesParentData(parentData);
	};

	const handleShowVariants = () => {
		setShowVariants((prev) => !prev);
	};

	const handleShowDuplicates = () => {
		setShowDuplicates((prev) => !prev);
	};

	const handleShowProfile = () => {
		setShowProfile((prev) => !prev);
		setProfileError(null);
	};

	const handleLogin = (userData) => {
		axios.post(process.env.REACT_APP_SERVER_DOMAIN + "/api/users/login/" + userData.username, userData).then((res) => {
			if (res.data.error) {
				setLoginError(res.data.error);
			} else {
				const token = res.data.token;
				let newData;
				// Get the correct data based on the mode
				switch (props.mode) {
					case "loose":
						newData = res.data.user.loose;
						break;
					case "loose-graded":
						newData = res.data.user.looseGraded;
						break;
					case "moc":
						newData = res.data.user.moc;
						break;
					case "moc-graded":
						newData = res.data.user.mocGraded;
						break;
					default:
				}
				props.clearSharedLink();
				setMainData(newData);
				handleFullReset(newData);
				handleShowLoginForm(false);
				props.handleToken(token);
				localStorage.setItem("userData", JSON.stringify({ username: userData.username, token: token }));
				localStorage.setItem("scope", "base");
				localStorage.setItem("mode", "loose");
				props.setUsername(userData.username);
				navigate("/" + userData.username);
				handleShowNotification("Welcome back, " + userData.username + "!");
				setScope("base");
				props.setMode("loose");
				props.onTabClick("loose", true);
			}
		});
	};

	const handleLogout = () => {
		// Do a full reset
		axios.get(process.env.REACT_APP_SERVER_DOMAIN + "/api/users/starterdata/").then((res) => {
			const newData = res.data.loose;
			setCompact(false);
			setMainData(newData);
			handleFullReset(newData);
			props.setMode("loose");
			setScope("base");
			props.onTabClick("loose");
		});
		props.handleToken(null);
		props.setUsername("starterdata");
		localStorage.clear();
		handleShowNotification("You have logged out successfully");
	};

	const handleLoginSwitch = () => {
		setShowLoginForm((prev) => !prev);
		setShowUserSignUpForm((prev) => !prev);
		setSignupError(null);
		setLoginError(null);
	};

	const handleShowUserSignUpForm = () => {
		setSignupError(null);
		setShowUserSignUpForm((prev) => !prev);
		setShowLoginForm(false);
	};

	const handleShowLoginForm = () => {
		setShowLoginForm((prev) => !prev);
		setShowUserSignUpForm(false);
		setLoginError(null);
	};

	const handleShowForgotPasswordForm = () => {
		setShowForgotPasswordForm((prev) => !prev);
		setShowLoginForm(false);
	};

	const handleShowResetPasswordForm = () => {
		setShowResetPasswordForm((prev) => !prev);
	};

	const handleSignup = (newUserData) => {
		setSignupError(null);
		axios
			.post(process.env.REACT_APP_SERVER_DOMAIN + "/api/users/signup", { ...starterData, ...newUserData })
			.then((res) => {
				if (!res.data.error) {
					const token = res.data.token;
					setSignupError(null);
					handleShowUserSignUpForm();
					props.handleToken(token);
					let newData;
					// Get the correct data based on the mode
					switch (props.mode) {
						case "loose":
							newData = res.data._doc.loose;
							break;
						case "loose-graded":
							newData = res.data._doc.looseGraded;
							break;
						case "moc":
							newData = res.data._doc.moc;
							break;
						case "moc-graded":
							newData = res.data._doc.mocGraded;
							break;
						default:
					}
					props.clearSharedLink();
					setMainData(newData);
					handleFullReset(newData);
					localStorage.setItem("userData", JSON.stringify({ username: newUserData.username, token: token }));
					localStorage.setItem("scope", "base");
					localStorage.setItem("mode", "loose");
					props.setUsername(newUserData.username);
					navigate("/" + newUserData.username);
					handleShowNotification("Welcome, " + newUserData.username + "!", 5000);
					props.setMode("loose");
					props.onTabClick("loose", true);
				} else {
					setSignupError(res.data.error);
				}
			});
	};

	const handleShowNotification = (message, time) => {
		let delay = time || 2500;
		setNotificationMessage(message);
		setNotificationActive(true);
		setTimeout(() => {
			setNotificationActive(false);
		}, delay);
	};

	const handleTabClick = (tabMode) => {
		if (tabMode !== props.mode) {
			let message;
			switch (tabMode) {
				case "loose":
					message = "Showing Loose collection";
					break;
				case "loose-graded":
					message = "Showing Loose Graded collection";
					break;
				case "moc":
					message = "Showing MOC/MISB collection";
					break;
				case "moc-graded":
					message = "Showing MOC/MISB Graded collection";
					break;
				default:
			}
			handleFullReset(); // I may not need this call but I'm leaving it in until I'm 100% sure
			handleShowNotification(message);
			props.onTabClick(tabMode);
			localStorage.setItem("mode", tabMode);
		}
	};

	const handleSettingsChange = (settings) => {
		const formData = new FormData();
		formData.append("settings", JSON.stringify(settings));
		axios
			.put(process.env.REACT_APP_SERVER_DOMAIN + "/api/users/settings/" + props.username, formData, config)
			.then((res) => {
				if (!res.data.error) {
					handleShowProfile();
					handleShowNotification("Settings saved successfully");
					setCompact(settings.compactMode);
					setUserIsSharing(settings.userIsSharing);
				} else {
					setProfileError(res.data.error);
				}
			});
	};

	const handleForgotPassword = (userData) => {
		axios
			.post(process.env.REACT_APP_SERVER_DOMAIN + "/api/users/forgotpassword/" + userData.username, userData)
			.then((res) => {
				if (res.data.error) {
					setForgotPwFormError(res.data.error);
				} else {
					handleShowForgotPasswordForm();
					handleShowNotification("A password reset link has been emailed to you", 5000);
				}
			});
	};

	const handleResetPassword = (userData) => {
		axios.post(process.env.REACT_APP_SERVER_DOMAIN + "/api/users/resetpassword/", userData).then((res) => {
			if (res.data.error) {
				setResetPwFormError(res.data.error);
			} else {
				handleShowResetPasswordForm();
				handleShowNotification("Your password has been changed");
				handleShowLoginForm();
			}
		});
	};

	return (
		<AuthContext.Provider
			value={{ isLoggedIn: !!props.token, token: props.token, login: handleLogin, logout: handleLogout }}
		>
			<Notification active={notificationActive} message={notificationMessage}></Notification>

			{bigImage[0] && <BigImage onClose={handleCloseBigImage} image={bigImage[1]} customImage={bigImage[2]}></BigImage>}

			<div className="App">
				{showVariants && (
					<Variants
						variantsData={variantsData}
						parentData={variantsParentData}
						compact={false}
						mode={props.mode}
						handleImageClick={handleImageClick}
						username={props.username}
						onShowVariants={handleShowVariants}
						onShowDuplicates={handleShowDuplicates}
						showVariantForm={handleShowVariantForm}
						handleRefresh={handleRefresh}
						showNotification={handleShowNotification}
						setTheDuplicatesData={handleSetDuplicatesData}
						showDuplicateForm={handleShowDuplicateForm}
					></Variants>
				)}
				{showDuplicates && (
					<Duplicates
						duplicatesData={duplicatesData}
						duplicatesParentData={duplicatesParentData}
						variantsParentData={variantsParentData}
						compact={false}
						mode={props.mode}
						handleImageClick={handleImageClick}
						username={props.username}
						onShowDuplicates={handleShowDuplicates}
						showDuplicateForm={handleShowDuplicateForm}
						handleRefresh={handleRefresh}
						showNotification={handleShowNotification}
						setTheVariantsData={handleSetVariantsData}
						setTheDuplicatesData={handleSetDuplicatesData}
					></Duplicates>
				)}
				{addItemFormVisible && (
					<AddForm onSave={handleAddNewItem} onCancel={handleShowAddForm} handleRefresh={handleRefresh}></AddForm>
				)}
				{addVariantFormVisible && (
					<AddVariantForm
						parentData={variantsParentData}
						onSaveVariant={handleAddNewVariant}
						onCancel={handleShowVariantForm}
						handleRefresh={handleRefresh}
						mode={props.mode}
					></AddVariantForm>
				)}
				{addDuplicateFormVisible && (
					<AddDuplicateForm
						parentData={duplicatesParentData}
						onSaveDuplicate={handleAddNewDuplicate}
						onCancel={handleShowDuplicateForm}
						handleRefresh={handleRefresh}
						mode={props.mode}
					></AddDuplicateForm>
				)}
				{showLoginForm && (
					<Login
						handleSaveClick={handleLogin}
						onCancel={handleShowLoginForm}
						loginSwitch={handleLoginSwitch}
						loginError={loginError}
						showForgotPassword={handleShowForgotPasswordForm}
					></Login>
				)}
				{showForgotPasswordForm && (
					<ForgotPassword
						onCancel={handleShowForgotPasswordForm}
						handleSaveClick={handleForgotPassword}
						forgotPwFormError={forgotPwFormError}
					></ForgotPassword>
				)}
				{showResetPasswordForm && (
					<ResetPassword
						onCancel={handleShowResetPasswordForm}
						handleSaveClick={handleResetPassword}
						resetPwFormError={resetPwFormError}
					></ResetPassword>
				)}
				{showUserSignUpForm && (
					<UserSignUp
						handleSaveClick={handleSignup}
						onCancel={handleShowUserSignUpForm}
						signupError={signupError}
						loginSwitch={handleLoginSwitch}
					></UserSignUp>
				)}
				{showProfile && (
					<Profile
						closeProfile={handleShowProfile}
						username={props.username}
						profileSettings={{ compactMode: compact, userIsSharing: userIsSharing, password: "" }}
						onSave={handleSettingsChange}
						handleShowNotification={handleShowNotification}
						profileError={profileError}
					></Profile>
				)}
				<Header
					username={props.username}
					handleFullReset={handleFullReset}
					handleSearch={handleSearch}
					handleClearSearch={handleClearSearch}
					searchInput={searchInput}
					handleShowAddForm={handleShowAddForm}
					showUserSignUpForm={handleShowUserSignUpForm}
					onShowLoginForm={handleShowLoginForm}
					onLogout={handleLogout}
					handleShowNotification={handleShowNotification}
					sharedLink={props.sharedLink}
					paramUser={props.paramUser}
					mode={props.mode}
					onTabClick={handleTabClick}
					showProfile={handleShowProfile}
				></Header>
				<div className="content-wrapper">
					<CollectionSelect
						baseOfficial={baseOfficial}
						figures={figures}
						creaturesOfficial={creaturesOfficial}
						creatures={creatures}
						vehiclesOfficial={vehiclesOfficial}
						vehicles={vehicles}
						playsetsOfficial={playsetsOfficial}
						playsets={playsets}
						minirigsOfficial={minirigsOfficial}
						minirigs={minirigs}
						scope={scope}
						onChange={filterHandler}
					></CollectionSelect>
					<FilterContainer
						onChange={filterHandler}
						mode={props.mode}
						owned={ownedFilter}
						wantList={wantListFilter}
						exclude={excludeFilter}
						complete={completeFilter}
						scope={scope}
						cardbacks={debutCardbackFilter}
						hasVariants={hasVariantsFilter}
						completeOptionsVisible={completeOptionsVisible}
						ownedOfFiltered={ownedOfFiltered}
						count={filteredData.length}
						username={props.username}
						handleFullReset={handleFullReset}
						handleShowNotification={handleShowNotification}
						yearReleased={yearReleasedFilter}
						condition={conditionFilter}
						grade={gradeFilter}
						company={companyFilter}
						cardbackOwned={cardbackOwnedFilter}
					></FilterContainer>
					{props.dataLoaded ? (
						<Container
							data={sortTheData([...filteredData], "releaseOrder", "ASC")}
							compact={compact}
							handleImageClick={handleImageClick}
							username={props.username}
							token={props.token}
							onShowVariants={handleShowVariants}
							onShowDuplicates={handleShowDuplicates}
							isVariant={false}
							showVariantForm={handleShowVariantForm}
							showDuplicateForm={handleShowDuplicateForm}
							handleRefresh={handleRefresh}
							setTheVariantsData={handleSetVariantsData}
							setTheDuplicatesData={handleSetDuplicatesData}
							showNotification={handleShowNotification}
							mode={props.mode}
							scope={scope}
							sharedLink={props.sharedLink}
							handleShowUserSignUpForm={handleShowUserSignUpForm}
						></Container>
					) : (
						<Spinner />
					)}
				</div>
			</div>
			<Footer></Footer>
		</AuthContext.Provider>
	);
};

export default Wrapper;
