import React, { useReducer, useEffect } from "react";

import { validate } from "../../utils/validators";

const inputReducer = (state, action) => {
	switch (action.type) {
		case "CHANGE":
			return {
				...state,
				value: action.val,
				isValid: validate(action.val, action.validators)
			};
		case "TOUCH":
			return {
				...state,
				isTouched: true
			};
		default:
			return state;
	}
};

const Input = (props) => {
	let element;

	const [inputState, dispatch] = useReducer(inputReducer, {
		value: props.value || "",
		isValid: props.isValidStart || false,
		isTouched: false
	});

	const { id, onInput, matchAgainst } = props;
	const { value, isValid } = inputState;

	useEffect(() => {
		if (props.validate) {
			onInput(id, value, isValid, matchAgainst);
		}
	}, [props.validate, id, value, isValid, onInput, matchAgainst]);

	const changeHandler = (e) => {
		dispatch({ type: "CHANGE", val: e.target.value, validators: props.validators });
	};

	const touchHandler = () => {
		dispatch({
			type: "TOUCH"
		});
	};

	if (props.element === "input") {
		if (props.type === "text" || props.type === "password") {
			if (props.validate) {
				element = (
					<input
						type={props.type}
						value={inputState.value}
						id={props.id}
						onChange={changeHandler}
						onBlur={touchHandler}
						className={
							props.showPasswordError === true ||
							(inputState.isValid === false && inputState.isTouched && props.validators.length > 0)
								? " invalid-input"
								: ""
						}
					/>
				);
			} else {
				element = (
					<input
						type={props.type}
						value={props.value}
						id={props.id}
						onChange={props.onChange}
						className={props.className}
						readOnly={props.readOnly}
					/>
				);
			}
		} else if (props.type === "radio") {
			element = (
				<div>
					{props.radios.map((item, index) => (
						<p key={item.id}>
							<input
								type="radio"
								id={item.id}
								name={props.id}
								checked={item.checked}
								onChange={props.onChange}
								key={index}
							/>
							<label htmlFor={item.id}>{item.label}</label>
						</p>
					))}
				</div>
			);
		} else if (props.type === "checkbox") {
			element = (
				<div className="checkbox-cont">
					<input type="checkbox" id={props.id} value={props.value} onChange={props.onChange} checked={props.checked} />
					<label htmlFor={props.id}>{props.label}</label>
				</div>
			);
		}
	} else if (props.element === "textarea") {
		element = (
			<textarea
				id={props.id}
				rows={props.rows || 5}
				maxLength={props.maxLength || "300"}
				value={props.value}
				onChange={props.onChange}
			/>
		);
	} else if (props.element === "select") {
		element = (
			<select id={props.id} value={props.value} onChange={props.onChange}>
				{props.options.map((item, index) => (
					<option value={item.value} key={index}>
						{item.label}
					</option>
				))}
			</select>
		);
	}

	return (
		<div className={`form-control ${props.className && props.className}`}>
			{props.showLabel !== false && (
				<label htmlFor={props.id}>
					{props.label}{" "}
					{!inputState.isValid && inputState.isTouched && <span className="error-text"> {props.errorText}</span>}
					{props.showPasswordError && <span className="error-text"> {props.errorText}</span>}
				</label>
			)}
			{element}
		</div>
	);
};

export default Input;
