import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import Container from "../structures/Container";
import "./Variants.css";

const Variants = (props) => {
	useEffect(() => {
		if (props.variantsData.length === 0) {
			props.onShowVariants();
		}
	}, [props.variantsData]);

	const content = (
		<div className="variants-overlay">
			<style>
				{`
				body{
					overflow: hidden;
				}
				`}
			</style>
			<div className="variant-close">
				<div onClick={props.onShowVariants}>
					<i className="fa-solid fa-arrow-left"></i> Back
				</div>
				<i className="fa-solid fa-close" onClick={props.onShowVariants}></i>
			</div>
			<h2 className="variants-heading">
				<div>
					{props.variantsData.length} {props.parentData.name} variant{props.variantsData.length > 1 && "s"} (
					{props.variantsData.filter((item) => item.owned).length} owned)
					<br />
					<button onClick={props.showVariantForm}>Add a variant</button>
				</div>
			</h2>
			<Container
				data={props.variantsData}
				parentData={props.parentData}
				compact={false}
				mode={props.mode}
				handleImageClick={props.handleImageClick}
				username={props.username}
				onShowVariants={props.handleShowVariants}
				onShowDuplicates={props.onShowDuplicates}
				handleRefresh={props.handleRefresh}
				showNotification={props.showNotification}
				setTheDuplicatesData={props.setTheDuplicatesData}
				showDuplicateForm={props.showDuplicateForm}
			></Container>
		</div>
	);

	return ReactDOM.createPortal(content, document.getElementById("overlay-hook"));
};

export default Variants;
